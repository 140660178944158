import { Component, OnInit, HostListener, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { IClinicReportModel } from "src/app/models/clinic-report.model";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { IHealthUnit } from "src/app/_models/health-unit.model";
import { formatDate } from "@angular/common";
import { UnitsModel } from "src/app/_models/units.model";
import { Router, NavigationExtras } from "@angular/router";
import { IHourReportModel } from "src/app/_models/hour-report.model";
import { IinviteProfessional } from "src/app/_models/inviteprofessional.model";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";
import { Table } from "primeng/table";
import { cleanProperties } from "src/utils";
import { GetHealthProfessionalRequest } from "src/app/_models/health-professional.model";
import { ReportsService } from "src/app/_services/reports.service";
import { HttpClient } from "@angular/common/http";

const ELEMENT_DATA: IClinicReportModel[] = [];

@Component({
  selector: "medlog-professional",
  templateUrl: "./professional.component.html",
  styleUrls: ["./professional.component.scss"],
})
export class ProfessionalComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private healthUnitService: HealthUnitService,
    private healthProfessionalService: HealthProfessionalsService,
    private reportsService: ReportsService,
    private http: HttpClient
  ) {
    document.getElementById("menu").click();
  }

  myControl = new FormControl();
  results: any[];
  text: string;
  final: any;
  initial: any;
  showTable: boolean = false;
  cols: any[];

  loading = false;
  healthProfessionals;

  filteredOptions: Observable<string[]>;
  matAutoComplete: string;
  public unitFilter: IHealthUnit;
  report: IinviteProfessional[] = [];

  clinics: UnitsModel[];
  pt: any;
  title;
  searchText: string = null;
  previous: string;

  result = [];
  searchKeyword;
  sort = 0;
  lastProperties = {
    limit: 10,
    offset: 0,
  };
  lenght = 0;

  @ViewChild("dt", { static: true }) dt1: Table;

  // @HostListener('input') oninput() {
  //   this.searchItems();
  // }

  // private checkAgainstProperty(property: any): boolean {
  //   let value: boolean = false;

  //   if (property.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0) {
  //     value = true;

  //   }

  //   return value;
  // }

  // searchItems() {

  //   this.result = this.report.filter(o => this.checkAgainstProperty(o.nome));
  //   console.log(this.result, "search")

  //   if (this.result.length === 0) {
  //     return [-1];
  //   }
  //   return this.result;

  // }

  ngOnInit() {
    this.title = localStorage.getItem("titulo");
    this.loading = true;

    this.cols = [
      { field: "id", header: "#" },
      { field: "nome", header: "Nome" },
      { field: "especialidade", header: "Especialidade" },
      { field: "telefone", header: "telefone" },
      { field: "email", header: "E-mail" },
      { field: "registro", header: "Registro" },
    ];

    this.getProf(this.lastProperties);

    // this.healthProfessionalService.getHealthProfessionals(this.lastProperties).subscribe(
    //   data => {
    //     console.log(data, 'dataaa')

    //     this.healthProfessionals = data.results;
    //     this.lenght = data.count;
    //     this.loading = false;

    //     console.log(this.healthProfessionals);

    //     for (const professional of this.healthProfessionals) {
    //       console.log(professional, 'proffifif')

    //       var foto;
    //       if (professional?.profile?.photo == "http://teste.com/photo.jpg") {
    //         foto = "/medilog/assets/img/noImage.gif";
    //       } else {
    //         foto = professional?.profile?.photo;
    //       }

    //       let prof = {
    //         image: foto,
    //         id: professional.id,
    //         nome: professional.name,
    //         especialidade: professional.profile.occupation_area,
    //         telefone: professional.profile.cel_phone,
    //         email: professional.email,
    //         registro: this.formatDatePT(professional.member_since),
    //         enable: professional.enabled

    //       };

    //       this.report.push(prof);

    //     }
    //     console.log(this.report, 'oreport')
    //     this.result = this.report;

    //   }
    // );
  }

  getProf(event: any) {
    this.loading = true;

    // this.lastProperties.limit = event?.rows;
    this.lastProperties.offset = event?.first;

    const params: GetHealthProfessionalRequest = {
      limit: this.lastProperties.limit,
      offset: this.lastProperties.offset,
      search: this.searchText === "" ? null : this.searchText,
    };

    this.healthProfessionalService
      .getHealthProfessionals(cleanProperties(params))
      .subscribe(
        (data) => {
          this.loading = false;
          this.lenght = data.count;

          const professionals = [];

          for (const professional of data.results) {
            var foto;
            if (professional?.profile?.photo == "http://teste.com/photo.jpg") {
              foto = "/assets/img/noImage.gif";
            } else {
              foto = professional?.profile?.photo;
            }

            const prof = {
              image: foto,
              id: professional.id,
              nome: professional.name,
              occupation_area: professional?.profile?.occupation_area,
              area_of_expertise: professional?.profile?.area_of_expertise,
              telefone: professional?.profile?.cel_phone,
              email: professional.email,
              registro: this.formatDatePT(professional.member_since),
              enable: professional.enabled,
            };

            professionals.push(prof);
          }

          this.result = professionals;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  formatDatePT(data: string) {
    const date = new Date(data);

    var dataString = date.toISOString().split("T");

    var dataReplace = this.replaceAll(dataString[0], "-", "/");
    var novaData = new Date(dataReplace);

    /*var year = novaData.getFullYear();
    var month = novaData.getMonth() + 1;
    var day = novaData.getDay();
    var newMonth;
    var newDay;
  
    if (month < 10 ){
      
      newMonth = "0" + month;
    }else{
      newMonth = month;
    }
    
    if (day < 10 ){
      newDay = "0" + day;
    }else{
      newDay = day;
    }
  
    return newDay + "/" + newMonth + "/" + year;*/

    return novaData.toLocaleDateString();
  }

  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  addProfessional() {
    this.router.navigate(["/home/addprofessional"]);
  }

  cadProfessional() {
    this.router.navigate(["/home/cad-profissional"]);
  }

  disableProfessional(id: string) {
    // this.openDialog("Deseja desativar profissional?", id, false);

    this.healthProfessionalService.disableProfessional(id).subscribe(
      (res) => {
        this.openDialog("Profissional desativado com sucesso", id, true);
      },
      (error) => {
        this.openDialog("Erro ao desativar profissional", id, false);
      }
    );
  }

  enableProfissional(id: string) {
    this.healthProfessionalService.enableProfessional(id).subscribe(
      (res) => {
        this.openDialog("Profissional ativado com sucesso", id, true);
      },
      (error) => {
        this.openDialog("Erro ao ativar profissional", id, false);
      }
    );
  }

  openProfissional(event) {
    this.router.navigate(["/home/profile/", event.id]);
  }

  openDialog(texto, id, enable): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto, enableCancel: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "0") {
        //ok
        let objEnable = { enabled: enable };
        this.healthProfessionalService.updateProf(objEnable, id).subscribe(
          (data) => {
            this.loading = false;
            if (enable) {
              this.openDialogAviso("Profissional ativado");
            } else {
              this.openDialogAviso("Profissional desativado");
            }
          },
          (error) => {
            this.loading = false;
            this.openDialogAviso("Ocorreu um erro, tente novamente!");
            return error;
          }
        );
      } else {
        //cancel
      }
    });
  }

  openDialogAviso(texto): void {
    this.loading = true;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto, enableCancel: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loading = true;
    });
  }

  onSortEspecialidade() {
    if (this.sort == 0) {
      this.result.sort(function (a, b) {
        var nameA = a.especialidade.toLowerCase(),
          nameB = b.especialidade.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });

      this.sort = 1;
    } else {
      this.result.sort(function (a, b) {
        var nameA = a.especialidade.toLowerCase(),
          nameB = b.especialidade.toLowerCase();
        if (nameB < nameA)
          //sort string ascending
          return -1;
        if (nameB > nameA) return 1;
        return 0; //default return value (no sorting)
      });

      this.sort = 0;
    }
  }

  onSort() {
    if (this.sort == 0) {
      this.result.sort(function (a, b) {
        var nameA = a.nome.toLowerCase(),
          nameB = b.nome.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });

      this.sort = 1;
    } else {
      this.result.sort(function (a, b) {
        var nameA = a.nome.toLowerCase(),
          nameB = b.nome.toLowerCase();
        if (nameB < nameA)
          //sort string ascending
          return -1;
        if (nameB > nameA) return 1;
        return 0; //default return value (no sorting)
      });

      this.sort = 0;
    }
  }
  elementprint(element) {}

  exportPdf() {
    this.loading = true;
    this.reportsService.exportProfessionalsPdf().subscribe(
      (result) => {
        this.loading = false;
        window.open(result.file_url);

        return true;
      },
      (error) => {
        this.loading = false;
        this.openDialog("Não foi possível exportar o arquivo", null, true);
      }
    );
  }

  exportXlxs() {
    this.reportsService.exportProfessionalsXlxs().subscribe(
      (result) => {
        this.loading = false;
        window.open(result.file_url);

        return true;
      },
      (error) => {
        this.loading = false;
        this.openDialog("Não foi possível exportar o arquivo", null, true);
      }
    );
  }
}
