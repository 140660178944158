import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { addHours } from "date-fns";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConsolidatedShiftService {
  constructor(private http: HttpClient) {}

  public createConsolidedShift(register) {
    const [dias, mess, anos] = register.modelDataInicio
      .toLocaleDateString()
      .split("/");
    let start =
      `${anos + "-" + mess + "-" + dias}` +
      "T" +
      new Date(register.modelDataInicio).toLocaleTimeString();

    const [diae, mese, anoe] = register.modelDataInicio
      .toLocaleDateString()
      .split("/");
    let end =
      `${anoe + "-" + mese + "-" + diae}` +
      "T" +
      new Date(register.modelDataFim).toLocaleTimeString();

    start = start.replace("/", "-").substring(0, 17) + "00.000Z";
    end = end.replace("/", "-").substring(0, 17) + "00.000Z";

    register.modelDataInicio = addHours(new Date(start), 3);

    register.modelDataFim = addHours(new Date(end), 3);

    return this.http.post<any>(
      environment.apiUrl + "consolidated-shift-management/",
      register
    );
  }

  public editConsolidatedShift(body, id) {
    return this.http.patch<any>(
      environment.apiUrl + "consolidated-shift-management/" + id + "/",
      body
    );
  }

  public getLastRegisterConsolidedShift(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + "consolidated-shift/");
  }
}
