export class iUnit {
  name: string;
  type: string;
  cnpj: string;
  responsible: string;
  phone_number: string;
  cel_number: string;
  address: string;
  address_number: string;
  address_complement: string;
  city: string;
  state: string;
  latitude: string;
  longitude: string;
  radius: number;
  enabled: boolean;
  zip_code: string;
  neighbourhood: string;
  whatsapp_notification: boolean;
  app_notification: boolean;
  company: any;
  id: any;
  updated_at: string | number | Date;
  disabled_at: any;
  created_at: string | number | Date;
}
