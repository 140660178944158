import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IHealthProfessional } from "../_models/health-professional.model";
import { HttpClient } from "@angular/common/http";

import { RequestProfessionals } from "../_interface/request-professionals.interface";
import { cleanProperties } from "src/utils";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class HealthProfessionalsService {
  constructor(private http: HttpClient) { }

  public getHealthProfessionals(
    params?: any
  ): Observable<RequestProfessionals> {
    return this.http.get<RequestProfessionals>(
      environment.apiUrl + "health-professionals/",
      { params: params }
    );
  }

  public getHealthProfessionalsByCategory(
    id_category?: string
  ): Observable<any[]> {
    return this.http.get<any[]>(
      environment.apiUrl +
      "health-professionals/?occupation_area_id=" +
      id_category
    );
  }

  public getCompanyHealthProfessionalsCategory(
    query: any
  ): Observable<any[]> {
    let request: any = {

    };

    if (query.id_category) {
      request.occupation_area_id = query.id_category;
    }

    if (query.areas_of_expertise?.length) {
      request.areas_of_expertise = query.areas_of_expertise;
    }
    if (query.search) request.search = query.search;

    if (query.limit !== undefined && query.limit !== null) request.limit = query.limit;
    if (query.offset !== undefined && query.offset !== null) request.offset = query.offset;
    console.log(request)
    return this.http.get<any[]>(
      environment.apiUrl + "company-health-professionals/",
      {
        params: cleanProperties(request),
      }
    );
  }

  getCompanyHealthProfessionalsCategoryById(id): Observable<any> {
    return this.http.get<any[]>(
      environment.apiUrl + `company-health-professionals/${id}/`,
      {
      }
    );

  }

  public getHealthProfessionalsById(id): Observable<any> {
    return this.http.get<any[]>(
      environment.apiUrl + "health-professionals/" + id + "/"
    );
  }

  public updateProf(body, id) {
    return this.http.patch<IHealthProfessional>(
      environment.apiUrl + "health-professionals/" + id + "/",
      body
    );
  }

  public getProfessionalUnits(latitude, longitude, radius): Observable<any[]> {
    return this.http.get<any[]>(
      environment.apiUrl +
      "maps/?latitude=" +
      latitude +
      "&longitude=" +
      longitude +
      "&radius=" +
      radius +
      ""
    );
  }
  public getProfessionals(id): Observable<any[]> {
    return this.http.get<any[]>(
      environment.apiUrl + "health-professionals/" + id
    );
  }
  public disableProfessional(id) {
    return this.http.put(
      environment.apiUrl + "health-professionals/" + id + "/disable",
      {}
    );
  }
  public enableProfessional(id) {
    return this.http.put(
      environment.apiUrl + "health-professionals/" + id + "/enable",
      {}
    );
  }
}
