import { Component, OnInit } from "@angular/core";
import { IClinicReportModel } from "src/app/models/clinic-report.model";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as L from "leaflet";
import * as H from "esri-leaflet-geocoder";
import { CheckModel } from "src/app/_models/check.model";
import { ConsolidatedShiftService } from "src/app/_services/consolidated-shift.service";
import { PositionCheckService } from "src/app/_services/position-check.service";

const ELEMENT_DATA: IClinicReportModel[] = [];

@Component({
  selector: "medlog-checkin",
  templateUrl: "./checkin.component.html",
  styleUrls: ["./checkin.component.scss"],
})
export class CheckinComponent implements OnInit {
  id: any;
  private sub: any;
  loading = false;
  map;
  points = L.layerGroup();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private healthUnitService: HealthUnitService,
    private consolidatedShiftService: ConsolidatedShiftService,
    private positionCheckService: PositionCheckService,
    routeParams: ActivatedRoute
  ) {}

  cols: any[];
  docs: any[];
  colsContract: any[];
  contracts: any[];
  doctor: any[];
  title;
  nomeUnidade;
  idUnidade;
  dataAtual;
  horaAtual;
  tempoDecorrido;
  habilitarCheckIn;
  habilitarCheckOut;
  habilitarLocalizar;
  ultimosRegistros;
  public checkFilter: CheckModel;
  lat;
  long;
  entrada;
  horario;
  ultimosRegistrosNovo;
  arrayUnits = [];

  selectedUnit;
  ngOnInit() {
    var datas = JSON.parse(localStorage.getItem("userInfo"));

    this.title = "Olá, " + datas["name"];
    this.loading = false;
    localStorage.setItem("points", JSON.stringify(this.points));

    this.map = L.map("map").setView([-13.6597399, -69.6854868], 3);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "© leaflet",
    }).addTo(this.map);

    this.habilitarCheckIn = false;
    this.habilitarCheckOut = false;
    this.habilitarLocalizar = true;
    this.getLastRegister();
    this.dataAtual = "-";
    this.horaAtual = "-";
    this.tempoDecorrido = "-";
    this.nomeUnidade = "-";
    this.entrada = false;

    if (localStorage.getItem("registroAtivo")) {
      this.getLocation();
    }
  }

  verificarHorario() {
    var inicial = JSON.parse(localStorage.getItem("registroAtivo"));

    if (inicial) {
      var inicials = new Date(inicial.datetime);
      var getHourExit = new Date();
      var minutesSaida = getHourExit.getMinutes();
      minutesSaida = minutesSaida > 9 ? minutesSaida : 0 + minutesSaida;
      var minutosSaida;
      var digitosHora;
      var digitosMinutos;

      if (minutesSaida > 9) {
        minutosSaida = minutesSaida;
      } else {
        minutosSaida = "0" + minutesSaida;
      }

      var final = getHourExit.getHours() + ":" + getHourExit.getMinutes();
      var horarioInicio = inicials.getHours() + ":" + inicials.getMinutes();
      var splInicial = horarioInicio.split(":");
      var splFinal = final.split(":");

      var inicialMin =
        Number(Number(splInicial[0]) * 60) + Number(splInicial[1]);

      if (Math.trunc(inicialMin / 60) < 10) {
        digitosHora = "0" + Math.trunc(inicialMin / 60).toString();
      } else {
        digitosHora = Math.trunc(inicialMin / 60).toString();
      }

      if (inicialMin % 60 < 10) {
        digitosMinutos = "0" + (inicialMin % 60).toString();
      } else {
        digitosMinutos = (inicialMin % 60).toString();
      }

      this.horario = digitosHora + ":" + digitosMinutos + "m";
      var finalMin = Number(Number(splFinal[0]) * 60) + Number(splFinal[1]);

      this.verificarDiferencaHorario(inicialMin, finalMin);
    }
  }

  verificarDiferencaHorario(inicialMin, finalMin) {
    var totalMin = Number(finalMin - inicialMin);
    var minutos;
    if (totalMin > 9) {
      minutos = totalMin;
    } else {
      minutos = "0" + totalMin;
    }

    var digitosHora;
    var digitosMinutos;

    if (Math.trunc(totalMin / 60) < 10) {
      digitosHora = "0" + Math.trunc(totalMin / 60).toString();
    } else {
      digitosHora = Math.trunc(totalMin / 60).toString();
    }

    if (minutos % 60 < 10) {
      digitosMinutos = "0" + (minutos % 60).toString();
    } else {
      digitosMinutos = (minutos % 60).toString();
    }

    this.tempoDecorrido = digitosHora + ":" + digitosMinutos + "m";
  }

  showTime() {
    var timeNow = new Date();
    var hours = timeNow.getHours();
    var minutes = timeNow.getMinutes();
    var seconds = timeNow.getSeconds();

    var timeString = "" + (hours > 12 ? hours : hours);
    timeString += (minutes < 10 ? ":0" : ":") + minutes;
    timeString += (seconds < 10 ? ":0" : ":") + seconds;

    return timeString;
  }

  sortNumber(a, b) {
    return b.order - a.order;
  }

  getLastRegister() {
    this.loading = true;
    this.consolidatedShiftService
      .getLastRegisterConsolidedShift()
      .subscribe((data) => {
        this.ultimosRegistros = [];

        for (var i = 0; i < data["count"]; i++) {
          var dataEntrada = new Date(data["results"][i].start_datetime);
          var hours = dataEntrada.getHours();
          var minutes = dataEntrada.getMinutes();
          var seconds = dataEntrada.getSeconds();

          var timeStringEntrada = "" + (hours > 12 ? hours : hours);
          timeStringEntrada += (minutes < 10 ? ":0" : ":") + minutes;
          timeStringEntrada += (seconds < 10 ? ":0" : ":") + seconds;

          var dataSaida = new Date(data["results"][i].end_datetime);
          var hours = dataSaida.getHours();
          var minutes = dataSaida.getMinutes();
          var seconds = dataSaida.getSeconds();
          var timeStringSaida = "" + (hours > 12 ? hours : hours);
          timeStringSaida += (minutes < 10 ? ":0" : ":") + minutes;
          timeStringSaida += (seconds < 10 ? ":0" : ":") + seconds;

          var dataNew = new Date(data["results"][i].start_datetime);

          var dataJson = {
            nome: data["results"][i].health_unit_name,
            dataEntrada: dataEntrada.toLocaleDateString(),
            horaEntrada: timeStringEntrada,
            dataSaida: dataSaida.toLocaleDateString(),
            horaSaida: timeStringSaida,
            totalTrabalhado: data["results"][i].worked_hours,
            order: dataNew.getTime(),
          };

          this.ultimosRegistros.push(dataJson);

          if (i == data["count"] - 1) {
            this.ultimosRegistrosNovo = [];
            var arrayDados = this.ultimosRegistros.sort(this.sortNumber);
            for (var a = 0; a < 2; a++) {
              this.ultimosRegistrosNovo.push(arrayDados[a]);
            }
          }

          this.loading = false;

          let id = setInterval(() => {
            this.verificarHorario();

            if (this.entrada) {
              clearInterval(id);
              this.tempoDecorrido = "00:00:00";
            }
          }, 1000);
        }
      });
  }

  distance(lat1, lon1, lat2, lon2) {
    var p = 0.017453292519943295;
    var c = Math.cos;
    var a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }

  getLocation() {
    this.loading = true;
    if (navigator.geolocation) {
      var componentScreen = this;

      //componentScreen.lat = -25.437786; //position.coords.latitude;
      //componentScreen.long = -49.501198;//position.coords.longitude;

      navigator.geolocation.getCurrentPosition(
        function (position) {
          componentScreen.lat = position.coords.latitude;
          componentScreen.long = position.coords.longitude;
          componentScreen.points.eachLayer(function (layer) {
            layer.remove();
          });

          var achou = false;
          //TODO TIRAR O 0 E COLOCAR i E DROPDOWN DE UNIDADES
          componentScreen.healthUnitService
            .getHealthUnitsWeb()
            .subscribe((data) => {
              componentScreen.loading = false;
              for (var i = 0; i < data["results"].length; i++) {
                // if(!achou){

                var distancia = componentScreen.distance(
                  componentScreen.lat,
                  componentScreen.long,
                  data["results"][i].latitude,
                  data["results"][i].longitude
                );
                if (distancia < 1) {
                  achou = true;

                  if (!componentScreen.selectedUnit) {
                    componentScreen.selectedUnit = {
                      name: data["results"][i].name,
                      id: data["results"][i].id,
                    };
                  }
                  componentScreen.arrayUnits.push({
                    name: data["results"][i].name,
                    id: data["results"][i].id,
                  });

                  componentScreen.loading = false;
                  componentScreen.nomeUnidade = data["results"][i].name;
                  componentScreen.idUnidade = data["results"][i].id;
                  var dataHoje = new Date();
                  componentScreen.dataAtual = dataHoje.toLocaleDateString();
                  componentScreen.horaAtual = componentScreen.showTime();

                  componentScreen.tempoDecorrido = "00:00:00";

                  componentScreen.points.addLayer(
                    L.circle(
                      [
                        data["results"][i].latitude,
                        data["results"][i].longitude,
                      ],
                      data["results"][i].radius
                    ).addTo(componentScreen.map)
                  );
                  componentScreen.points.addLayer(
                    L.marker([
                      data["results"][i].latitude,
                      data["results"][i].longitude,
                    ]).addTo(componentScreen.map)
                  );
                  componentScreen.map.setZoom(22);
                  componentScreen.map.panTo([
                    data["results"][i].latitude,
                    data["results"][i].longitude,
                  ]);

                  if (localStorage.getItem("registroAtivo")) {
                    componentScreen.habilitarCheckOut = true;
                    componentScreen.habilitarLocalizar = false;
                  } else {
                    componentScreen.horario = componentScreen.showTime();
                    componentScreen.habilitarCheckIn = true;
                    componentScreen.habilitarLocalizar = false;
                  }
                }

                //}
              }
            });

          // Do something magical...
        },
        function () {
          alert("Oops! An error occurred.");
        }.bind(this)
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  uuid4() {
    function hex(s, b) {
      return (
        s +
        (b >>> 4).toString(16) + // high nibble
        (b & 0b1111).toString(16)
      ); // low nibble
    }

    let r = crypto.getRandomValues(new Uint8Array(16));

    r[6] = (r[6] >>> 4) | 0b01000000; // Set type 4: 0100
    r[8] = (r[8] >>> 3) | 0b10000000; // Set variant: 100

    return (
      r.slice(0, 4).reduce(hex, "") +
      r.slice(4, 6).reduce(hex, "-") +
      r.slice(6, 8).reduce(hex, "-") +
      r.slice(8, 10).reduce(hex, "-") +
      r.slice(10, 16).reduce(hex, "-")
    );
  }

  registrar() {
    if (this.habilitarCheckIn) {
      var tzoffset = new Date().getTimezoneOffset(); //offset in milliseconds
      var dataPonto = new Date(Date.now() - tzoffset).toISOString();

      var groupId = this.uuid4();
      localStorage.setItem("groupId", groupId);

      this.checkFilter = new CheckModel();
      this.checkFilter.group = groupId;
      this.checkFilter.health_unit = this.selectedUnit.id;
      this.checkFilter.latitude = parseFloat(this.lat).toFixed(6);
      this.checkFilter.longitude = parseFloat(this.long).toFixed(6);
      this.checkFilter.type = "check-in";
      this.checkFilter.datetime = dataPonto;
      this.positionCheckService.checkIn(this.checkFilter).subscribe((data) => {
        localStorage.setItem("registroAtivo", JSON.stringify(data));
        this.habilitarCheckOut = true;
        this.habilitarCheckIn = false;
        this.habilitarLocalizar = false;
        this.getLastRegister();
      });
    }
  }

  registrarSaida() {
    if (this.habilitarCheckOut) {
      var groupId = localStorage.getItem("groupId");

      var tzoffset = new Date().getTimezoneOffset(); //offset in milliseconds
      var dataPonto = new Date(Date.now() - tzoffset).toISOString();

      this.checkFilter = new CheckModel();
      this.checkFilter.group = groupId;
      this.checkFilter.health_unit = this.idUnidade;
      this.checkFilter.latitude = parseFloat(this.lat).toFixed(6);
      this.checkFilter.longitude = parseFloat(this.long).toFixed(6);
      this.checkFilter.type = "check-out";
      this.checkFilter.datetime = dataPonto;

      this.positionCheckService.checkIn(this.checkFilter).subscribe((data) => {
        localStorage.removeItem("registroAtivo");
        this.habilitarCheckOut = false;
        this.habilitarCheckIn = false;
        this.habilitarLocalizar = true;
        localStorage.removeItem("groupId");
        this.getLastRegister();
        this.entrada = true;
        this.tempoDecorrido = "00:00:00";
        this.horario = this.showTime();
      });
    }
  }

  showPosition(position) {
    //console.log( "Latitude: " + position.coords.latitude +" Longitude: " + position.coords.longitude);
    var points = JSON.parse(localStorage.getItem("points"));
    var controller = JSON.parse(localStorage.getItem("this"));
  }

  addProfessional() {
    this.router.navigate(["/home/addprofessional"]);
  }
}
