import {
  Component,
  OnInit,
  HostListener,
  Inject,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { HealthUnitService } from "src/app/_services/health-unit.service";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";
import { ModalService } from "src/app/_services/modal.service";
import { DetailRegistroComponent } from "../detailregistro/detailregistro.component";
import { hoursToSeconds, minutesToSeconds } from "date-fns";
import { cleanProperties } from "src/utils";
import { GridService } from "src/app/_services/grid.service";
import { CreateGridComponent } from "../create-grid/create-grid.component";
import { CREATE_GRID_TOKEN } from "../create-grid/create-grid.token";
export var edit: boolean;

@Component({
  selector: "medlog-grid",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.scss"],
})
export class GridComponent implements OnInit {
  dateFormated: string;
  dateCreatedFormated: string;
  hourEnd: string;
  hourStart: string;
  hourDuration: string;
  constructor(
    private healthUnitService: HealthUnitService,
    @Inject(CREATE_GRID_TOKEN)
    private createGridComponent: TemplateRef<CreateGridComponent>,
    private readonly dialog: MatDialog,
    private healthProfessionalService: HealthProfessionalsService,
    private gridService: GridService
  ) {
    document.getElementById("menu").click();
  }

  text: string;
  initial: any;
  healthUnits;
  selectedUnit;
  loading = true;
  gradeData;
  selectedGrade;
  visualizarData = [];
  selectedVisualizar;
  gradeAllData;
  title;
  dialogs: DetailRegistroComponent;
  allGrid;

  ngOnInit() {
    this.title = localStorage.getItem("titulo");

    let dat = { results: [] };
    this.gradeData = dat;
    this.gradeData.results.unshift({ name: "Selecione a Grade", id: 0 });
    this.selectedGrade = this.gradeData.results[0];
    this.visualizarData.push({ name: "Selecione a Visualização", id: 0 });
    this.visualizarData.push({ name: "Todas", id: 1 });
    this.visualizarData.push({ name: "Ativas", id: 2 });
    this.visualizarData.push({ name: "Inativas", id: 3 });

    const params = {
      health_unit_status: "enable",
    };

    this.healthUnitService.getHealthUnits(params).subscribe((data) => {
      this.loading = false;
      this.healthUnits = data;

      this.healthUnits.results.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      //this.healthUnits.results.reverse();
      this.healthUnits.results.unshift({ name: "Selecione a Unidade", id: 0 });
    });
    this.selectedUnit = { name: "Selecione a Unidade", id: 0 };
  }

  getAllGrids() {
    if (this.selectedGrade.id == 0) {
      this.openDialogSucesso("Selecione uma grade!");
    } else {
      this.gradeAllData = this.selectedGrade;

      this.dateFormated = this.formatDate(this.gradeAllData.updated_at);
      this.dateCreatedFormated = this.formatDate(this.gradeAllData.created_at);
      for (let i = 0; i < this.gradeAllData.planned_workshifts.length; i++) {
        this.calculateHour(i);
      }
      for (
        let history = 0;
        history < this.gradeAllData.history.length;
        history++
      ) {
        for (
          let iplanned_workshifts = 0;
          iplanned_workshifts <
          this.gradeAllData.history[history].planned_workshifts.length;
          iplanned_workshifts++
        ) {
          this.calculateHourHistory(iplanned_workshifts, history);
        }
      }
    }
  }

  getGrid() {
    this.gradeAllData = "";
    if (this.selectedUnit.id == 0) {
      let dat = { results: [] };
      this.gradeData = dat;
      this.gradeData.results.push({ name: "Selecione a Grade", id: 0 });
    } else {
      this.loading = true;
      this.gridService.getGrids(this.selectedUnit.id).subscribe((data) => {
        this.loading = false;
        this.gradeData = data;

        this.gradeData.results.sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0; //default return value (no sorting)
        });
        this.gradeData.results.unshift({ name: "Selecione a Grade", id: 0 });
      });
    }
  }

  formatHour(data) {
    var dataHora = data.split(":");
    var segundos = dataHora[2].split(".");
    return dataHora[0] + ":" + dataHora[1] + ":" + segundos[0];
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  formatDate(data: string) {
    var array = data.split("");
    var year = array[0] + array[1] + array[2] + array[3];
    var month = array[5] + array[6];
    var day = array[8] + array[9];
    var hour = array[11] + array[12];
    var hourInt = parseInt(hour) - 3;
    if (hourInt < 0) {
      hourInt = hourInt + 24;
    }
    var hour = hourInt.toLocaleString();
    var minute = array[14] + array[15];
    return day + "/" + month + "/" + year + "  " + hour + ":" + minute;
  }

  //formatDate(data: string){
  //"2019-04-01T22:23:52Z"
  // var array =data.split('');
  // var year = array[0]+array[1]+array[2]+array[3];
  // var month = array[5]+array[6];
  // var day = array[8]+array[9];
  //var hour = array[11]+array[12];
  // var minute = array[14]+array[15];

  // var month = data.getMonth() + 1;
  // var day = data.getDay();
  // var  hour = data.getHours();
  // var minute = data.getMinutes();
  // var newMonth;
  // var newDay;

  // if (month < 10 ){
  //   newMonth = "0" + month;
  // }

  // if (day < 10 ){
  //   newDay = "0" + day;
  // }

  //return year + "-" + newMonth + "-" + newDay +"T"+hour+":"+minute+":00Z";
  //return year + "-" + newMonth + "-" + newDay;
  // return day + '/' + month + '/' + year + '  '+hour+':'+minute;
  //}

  async openGrade() {
    edit = false;

    const dialogRef = this.dialog.open(this.createGridComponent, {
      width: "900px",
      height: "450px",
      panelClass: "azulPanel",
      //disableClose: true,
      data: {
        description: "",
        selectUnity: this.selectedUnit.id ? this.selectedUnit : null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loading = true;
        this.gridService.sendGrid(cleanProperties(result)).subscribe(
          async (data) => {
            const unit = await this.healthUnits.results.filter(
              (unit) => unit.id === data.health_unit
            );

            this.selectedUnit = { ...unit[0] };
            await this.getGrid();
            this.selectedGrade = { ...data };

            this.loading = false;
            this.openDialogSucesso("Grade criada com sucesso!");
            this.getAllGrids();
          },
          (error) => {
            this.loading = false;
            this.openDialog("Ocorreu um erro, tente novamente!");
            return error;
          }
        );
      }
    });
  }

  onKeyDate(event: any) {
    event.target.value = event.target.value.replace(
      /^(\d{2})(\d{2})(\d{4})/,
      "$1/$2/$3"
    );
  }

  verifyDate(valor) {
    valor.action = true;
  }

  openDialogSucesso(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  deleteGrid() {
    var texto = "Deseja excluir a grade?";
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto, enableCancel: true },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "0") {
        //ok
        this.loading = true;
        this.gridService.deleteGrid(this.selectedGrade.id).subscribe((data) => {
          this.loading = false;
          this.openDialogSucesso("Grade excluída com sucesso!");
          this.getGrid();
        });
      } else {
        //cancel
      }
    });
  }

  clearTable() {
    this.gradeAllData = "";
  }

  editGrid() {
    edit = true;

    const dialogRef = this.dialog.open(this.createGridComponent, {
      width: "900px",
      height: "450px",
      panelClass: "azulPanel",
      //disableClose: true,
      data: { description: "", grid: this.gradeAllData },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        (error) => {
          this.openDialog("Informe a Categoria Profissional!");
          return error;
        };

        this.loading = true;
        this.gridService
          .updateGrid(this.gradeAllData.id, cleanProperties(result))
          .subscribe(
            (data) => {
              this.getGrid();
              this.loading = false;
              this.openDialogSucesso("Grade alterada com sucesso!");
            },
            (error) => {
              this.loading = false;
              this.openDialog("Ocorreu um erro, tente novamente!");
              return error;
            }
          );
      }
    });
  }
  calculateHour(position) {
    if (
      this.gradeAllData.planned_workshifts[position].start_time != "" &&
      this.gradeAllData.planned_workshifts[position].duration != ""
    ) {
      var today = new Date();

      var start =
        this.gradeAllData.planned_workshifts[position].start_time.split(":");
      var duration =
        this.gradeAllData.planned_workshifts[position].duration.split(":");
      let startHours = parseInt(start[0]);
      let startMinutes = parseInt(start[1]);
      let durationHours = parseInt(duration[0]);
      let durationMinutes = parseInt(duration[1]);
      startHours = hoursToSeconds(startHours);
      startMinutes = minutesToSeconds(startMinutes);
      durationHours = hoursToSeconds(durationHours);
      durationMinutes = minutesToSeconds(durationMinutes);

      const allSeconds =
        startHours + startMinutes + durationHours + durationMinutes;

      const endHours = this.convertHMS(allSeconds);
      this.gradeAllData.planned_workshifts[position].horaFim = endHours;
      //starts.push("00");
      //console.log(splitHora, starts);

      //var hh;
      //if (splitHora[0] < 10) {
      //  hh = Number(splitHora[0].toString());
      //} else {
      // hh = splitHora[0];
      //}
      //today.setHours(hh, 0, 0);

      //var jj;
      //if (starts[0] < 10) {
      //  jj = Number(starts[0].toString());
      // } else {
      //   jj = starts[0];
      // }

      //today.setHours(Number(today.getHours()) + Number(jj));
      //this.dataWorkedSheet[position].horaFim = today.toLocaleTimeString();
      //console.log(this.dataWorkedSheet[position].horaFim);
    }
  }
  calculateHourHistory(position, history) {
    if (
      this.gradeAllData.history[history].planned_workshifts[position]
        .start_time != "" &&
      this.gradeAllData.history[history].planned_workshifts[position]
        .duration != ""
    ) {
      var today = new Date();

      var start =
        this.gradeAllData.history[history].planned_workshifts[
          position
        ].start_time.split(":");
      var duration =
        this.gradeAllData.history[history].planned_workshifts[
          position
        ].duration.split(":");
      let startHours = parseInt(start[0]);
      let startMinutes = parseInt(start[1]);
      let durationHours = parseInt(duration[0]);
      let durationMinutes = parseInt(duration[1]);
      startHours = hoursToSeconds(startHours);
      startMinutes = minutesToSeconds(startMinutes);
      durationHours = hoursToSeconds(durationHours);
      durationMinutes = minutesToSeconds(durationMinutes);

      const allSeconds =
        startHours + startMinutes + durationHours + durationMinutes;

      const endHours = this.convertHMS(allSeconds);
      this.gradeAllData.history[history].planned_workshifts[position].horaFim =
        endHours;
      //starts.push("00");
      //console.log(splitHora, starts);

      //var hh;
      //if (splitHora[0] < 10) {
      //  hh = Number(splitHora[0].toString());
      //} else {
      // hh = splitHora[0];
      //}
      //today.setHours(hh, 0, 0);

      //var jj;
      //if (starts[0] < 10) {
      //  jj = Number(starts[0].toString());
      // } else {
      //   jj = starts[0];
      // }

      //today.setHours(Number(today.getHours()) + Number(jj));
      //this.dataWorkedSheet[position].horaFim = today.toLocaleTimeString();
      //console.log(this.dataWorkedSheet[position].horaFim);
    }
  }
  convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours: any = Math.floor(sec / 3600); // get hours
    let minutes: any = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds: any = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02

    if (hours > 24) {
      hours = hours - 24;
    }

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes > 60) {
      minutes = minutes - 60;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    return hours + ":" + minutes + ":" + "00"; // Return is HH : MM : SS
  }
}
