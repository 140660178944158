import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { IClinicReportModel } from "src/app/models/clinic-report.model";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { IHealthUnit } from "src/app/_models/health-unit.model";
import { formatDate } from "@angular/common";
import { UnitsModel } from "src/app/_models/units.model";
import { Router } from "@angular/router";
import { IHourReportModel } from "src/app/_models/hour-report.model";
import { IinviteProfessional } from "src/app/_models/inviteprofessional.model";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { IHealthProfessional } from "src/app/_models/health-professional.model";
import { IinviteGrid } from "src/app/_models/invitegrid.model";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";
import { InvitationsService } from "../../_services/invitations.service";
import { PositionCheckService } from "src/app/_services/position-check.service";

const ELEMENT_DATA: IClinicReportModel[] = [];

@Component({
  selector: "medlog-addrofessional",
  templateUrl: "./addprofessional.component.html",
  styleUrls: ["./addprofessional.component.scss"],
})
export class AddProfessionalComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private healthUnitService: HealthUnitService,
    private healthProfessionalService: HealthProfessionalsService,
    private invitationsService: InvitationsService,
    private positionCheckService: PositionCheckService
  ) {}

  myControl = new FormControl();
  results: any[];
  text: string;
  final: any;
  initial: any;
  showTable: boolean = false;
  cols: any[];

  loading = false;

  filteredOptions: Observable<string[]>;
  matAutoComplete: string;
  public unitFilter: IHealthUnit;
  report: IinviteGrid[] = [];
  invite;
  invites;

  clinics: UnitsModel[];
  pt: any;
  healthProfessionals;

  emailInvite;
  title;

  ngOnInit() {
    this.title = localStorage.getItem("titulo");
    this.showInvites();
  }

  addUnit() {
    this.router.navigate(["/home/addunidades"]);
  }
  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.showInvites();
    });
  }

  openDialogSucesso(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.showInvites();
    });
  }

  resend(id) {
    this.loading = true;

    this.invitationsService.renewInvite(id).subscribe(
      (data) => {
        this.openDialogSucesso("Convite Enviado com sucesso!!");
        this.loading = false;
      },

      (error) => {
        this.loading = false;
        this.openDialog("Ocorreu um erro, tente novamente!");
        return error;
      }
    );
  }

  sendInvite() {
    if (this.emailInvite == "") {
      this.openDialog("Necessário preencher o e-mail!!");
    } else {
      this.loading = true;
      this.invite = new IHealthProfessional();
      this.invite.health_professional_email = this.emailInvite;

      this.invitationsService.sendInvite(this.invite).subscribe(
        (data) => {
          this.emailInvite = "";
          this.openDialogSucesso("Convite Enviado com sucesso!!");

          this.loading = false;
        },

        (error) => {
          this.loading = false;
          this.openDialog("Este Profissional já está cadastrado na empresa!");
          return error;
        }
      );
    }
  }

  deleteUser(id) {
    this.openDialogOption("Deseja excluir convite?", true, id);
  }

  showInvites() {
    this.report = [];
    this.cols = [
      { field: "health_professional_email", header: "E-mail" },
      { field: "action", header: "Opções" },
    ];

    this.loading = true;
    this.invitationsService.showInvites().subscribe((data) => {
      this.invites = data;

      for (let i = 0; i < this.invites.count; i++) {
        let inv = {
          health_professional_email:
            this.invites.results[i].health_professional_email,
          action: this.invites.results[i].id,
        };
        this.report.push(inv);
      }

      this.loading = false;
    });
  }

  backProfessional() {
    this.router.navigate(["home/professional"]);
  }

  openDialogOption(texto, enable, id): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto, enableCancel: enable },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "0") {
        this.loading = true;
        this.invitationsService.delete(id).subscribe(
          (data) => {
            this.openDialogSucesso("Exclusão realizada com sucesso!");
            this.loading = false;
          },

          (error) => {
            this.loading = false;
            this.openDialog("Este Profissional já está vinculado à empresa.");
            return error;
          }
        );
      } else {
        //cancel
      }
    });
  }
}
