import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { IHealthUnit } from "src/app/_models/health-unit.model";
import { DialogComponent } from "../dialog/dialog.component";
import {
  DateTimeAdapter,
  OwlDateTimeIntl,
  OwlDateTimeComponent,
} from "@danielmoncada/angular-datetime-picker";
import { GridService } from "src/app/_services/grid.service";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
export interface DialogData {
  description: string;
  enableCancel: boolean;
  unit: string;
  grid: string;
  period: string;
  day: number;
  start_time: string;
  end_time: string;
  prof_id: string;
  occupation_area_id: string;
}

@Component({
  selector: "medlog-detailregistro",
  templateUrl: "./detailregistro.component.html",
  styleUrls: ["./detailregistro.component.scss"],
})
export class DetailRegistroComponent implements OnInit {
  constructor(
    private dateTimeAdapter: DateTimeAdapter<any>,
    private owlDateTimeIntl: OwlDateTimeIntl,
    public dialogRef: MatDialogRef<DetailRegistroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private healthUnitService: HealthUnitService,
    private healthProfessionalService: HealthProfessionalsService,
    private gridService: GridService,
    public dialog: MatDialog
  ) {
    this.dateTimeAdapter.setLocale("pt-BR");
    owlDateTimeIntl.setBtnLabel = "";
    owlDateTimeIntl.cancelBtnLabel = "";
    this.searchSubject.pipe(debounceTime(500)).subscribe((searchTerm) => {
      this.getHealthProfessionals(searchTerm);
    });
  }
  private searchSubject = new Subject<string>();

  limit: number = 20;
  offset: number = 0;
  healthUnits;
  selectedUnit;
  healthProfessionals;
  selectedProfessional;
  loading = true;
  pt: any;
  dateTime;
  modelDataInicio;
  modelDataFim;
  datainicial;
  dataFinal;
  datainicialFormatada;
  datafinalFormatada;
  periods = {
    results: [{ name: "Selecione o periodo", id: null }],
  };
  selectedPeriod = { name: "Selecione o periodo", id: null };

  gradeData: any = {
    results: [{ name: "Selecione a grade", id: null }],
  };
  selectedGrade: any = { name: "Selecione a grade", id: null };
  onNoClick(): void {
    this.dialogRef.close("0");
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  async ngOnInit() {
    this.dateTime = new Date();

    const params = {
      health_unit_status: "enable",
    };

    this.healthUnitService.getHealthUnits(params).subscribe((data) => {
      this.healthUnits = data;

      this.healthUnits.results.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.healthUnits.results.unshift({ name: "Selecione a Unidade", id: 0 });

      if (this.data.unit) {
        const selectUnit = this.healthUnits.results.find(
          (unit) => unit.id === this.data.unit
        );
        if (selectUnit) this.selectedUnit = selectUnit;
        this.getGrid();
      }

      //this.healthUnits.results.reverse();
    });
    this.loading = true;
    await this.getHealthProfessionals();
    // if (this.data.occupation_area_id) {
    //   this.healthProfessionalService
    //     .getCompanyHealthProfessionalsCategory(this.data.occupation_area_id)
    //     .subscribe((data) => {
    //       this.healthProfessionals = data;

    //       this.healthProfessionals.results.push({
    //         name: "--TODOS OS PROFISSIONAIS--",
    //         user_type: 0,
    //         email: 0,
    //       });

    //       this.healthProfessionals.results.sort(function (a, b) {
    //         var nameA = a.name.toLowerCase(),
    //           nameB = b.name.toLowerCase();
    //         if (nameA < nameB)
    //           //sort string ascending
    //           return -1;
    //         if (nameA > nameB) return 1;
    //         return 0; //default return value (no sorting)
    //       });

    //       //this.healthProfessionals.results.reverse();
    //       if (this.data.prof_id) {
    //         const selectProf = this.healthProfessionals.results.find(
    //           (prof) => prof.health_professional_id === this.data.prof_id
    //         );
    //         if (selectProf) this.selectedProfessional = selectProf;
    //       }
    //       this.loading = false;
    //     });
    // } else {
    //   this.healthProfessionalService
    //     .getCompanyHealthProfessionalsCategory({})
    //     .subscribe((data) => {
    //       this.healthProfessionals = data;

    //       this.healthProfessionals.results.push({
    //         name: "--TODOS OS PROFISSIONAIS--",
    //         user_type: 0,
    //         email: 0,
    //       });

    //       this.healthProfessionals.results.sort(function (a, b) {
    //         var nameA = a.name.toLowerCase(),
    //           nameB = b.name.toLowerCase();
    //         if (nameA < nameB)
    //           //sort string ascending
    //           return -1;
    //         if (nameA > nameB) return 1;
    //         return 0; //default return value (no sorting)
    //       });

    //       //this.healthProfessionals.results.reverse();
    //       if (this.data.prof_id) {
    //         const selectProf = this.healthProfessionals.results.find(
    //           (prof) => prof.health_professional_id === this.data.prof_id
    //         );
    //         if (selectProf) this.selectedProfessional = selectProf;
    //       }
    //       this.loading = false;
    //     });
    // }
    this.selectedUnit = { name: "SELECIONE A UNIDADE", id: 0 };

    this.selectedProfessional = {
      name: "TODOS OS PROFISSIONAIS",
      user_type: 0,
      email: 0,
    };

    var initialDate = new Date();

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: [
        "Domingo",
        "Segunda Feira",
        "Terça Feira",
        "Quarta Feira",
        "Quinta Feira",
        "Sexta Feira",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",
      dateFormat: "mm/dd/yy",
    };
  }

  ngOnDestroy() {
    this.searchSubject.unsubscribe();
  }

  onKeyup(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    this.searchSubject.next(inputElement.value); // Atualiza o Subject
  }

  getHealthProfessionals(search?: any){

    const query = {
      limit : this.limit,
      offset: this.offset,
      id_category: this.data.occupation_area_id,
      search
    }
    console.log(query)
    this.healthProfessionalService
    .getCompanyHealthProfessionalsCategory(query)
    .subscribe((data) => {
      this.healthProfessionals = data;

      this.healthProfessionals.results.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.healthProfessionals.results.unshift({
        name: "Todos os Profissionais",
        user_type: 0,
        email: 0,
      });

      //this.healthProfessionals.results.reverse();
      this.loading = false;
    });
  }

  chosenDateHandler(datepicker: OwlDateTimeComponent<any>, input: string) {
    if (input === "ATE") {
      this.modelDataFim = datepicker.selected;
    } else {
      this.modelDataInicio = datepicker.selected;
    }
  }

  createRegister() {
    if (
      this.selectedUnit &&
      this.modelDataInicio != 0 &&
      this.modelDataFim != 0
    ) {
      this.datainicial =
        new Date(this.modelDataInicio + new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 19) + "Z";
      this.dataFinal =
        new Date(this.modelDataFim + new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 19) + "Z";

      this.datainicial = this.datainicial.substring(0, 17) + "00.000Z";
      this.dataFinal = this.dataFinal.substring(0, 17) + "00.000Z";

      var parametros = new IHealthUnit();

      parametros.health_unit = this.selectedUnit.id;
      parametros.health_professional =
        this.selectedProfessional.health_professional_id;
      parametros.adjusted_start_datetime = this.datainicial;
      parametros.adjusted_end_datetime = this.dataFinal;
      parametros.disable_adjustment = "false";
      //   parametros.image = this.selectedProfessional.profile.photo;
      parametros.doctor = this.selectedProfessional.name;
      parametros.clinic = this.selectedUnit.name;
      parametros.modelDataInicio = this.modelDataInicio;
      parametros.modelDataFim = this.modelDataFim;
      // parametros.period = this.selectedPeriod.name;
      if (this.selectedGrade.id) parametros.grid_id = this.selectedGrade.id;

      this.dialogRef.close(parametros);
    } else {
      this.loading = false;
      this.openDialog("Necessário preencher os campos.");
    }
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  openDialogSucesso(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }
  getGrid() {
    if (this.selectedUnit.id == 0) {
      let dat = { results: [] };
      this.gradeData = dat;
      this.gradeData.results.push({ name: "Selecione a Grade", id: 0 });
    } else {
      this.loading = true;
      this.gridService.getGrids(this.selectedUnit.id).subscribe((data) => {
        this.loading = false;
        this.gradeData = data;

        this.gradeData.results.sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0; //default return value (no sorting)
        });
        this.gradeData.results.unshift({ name: "Selecione a Grade", id: 0 });

        if (this.data.grid) {
          const selectGrid = this.gradeData.results.find(
            (grid) => grid.id === this.data.grid
          );
          if (selectGrid) this.selectedGrade = selectGrid;

          if (this.data.start_time) this.modelDataInicio = this.data.start_time;
          if (this.data.end_time) this.modelDataFim = this.data.end_time;

          this.getPeriods();
        }
      });
    }
  }
  getPeriods() {
    this.periods.results = [{ name: "Selecione o periodo", id: null }];

    let dat = { results: [{ name: "Selecione o periodo", id: null }] };
    this.selectedGrade.planned_workshifts.map((turno) => {
      dat.results.push({ name: turno.name, id: turno.name });
    });
    this.periods = dat;

    if (this.data.period) {
      const selectedPeriod = this.periods.results.find(
        (period) => period.name === this.data.period
      );
      if (selectedPeriod) this.selectedPeriod = selectedPeriod;
    }
  }
}
