import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { MatDialog, MatPaginator, MatTableDataSource } from "@angular/material";
import { ReviewService } from "src/app/_services/review.service";
import { GridService } from "src/app/_services/grid.service";
import { ScheduleService } from "src/app/_services/schedule.service";
import { ConsolidatedShiftService } from "../../_services/consolidated-shift.service";
import { WorkSlotService } from "../../_services/work-slot.service";
import { ScheduleMonitoringModalComponent } from "../schedule-monitoring-modal/schedule-monitoring-modal.component";
import { SCHEDULE_MONITORING_MODAL_TOKEN } from "../schedule-monitoring-modal/schedule-monitoring-modal.token";
import { format, getMonth, isAfter, set, subHours, subMinutes } from "date-fns";
import { el, ptBR } from "date-fns/locale";
import { DialogComponent } from "../dialog/dialog.component";
import {
  formatDate,
  formatHour,
  getHoursMinuts,
  sumDuration,
  sumTimes,
} from "src/utils";
import { Review } from "src/app/_models/review.model";
import { DetailRegistroComponent } from "../detailregistro/detailregistro.component";
import { EditRegistroComponent } from "../editregistro/editregistro.component";
import { WorkShiftService } from "src/app/_services/work-shift.service";

export class ConsolidatedShift {
  health_professional_name: string;
  start_datetime: Date;
  end_datetime: Date;
  worked_hours: Date;
}

@Component({
  selector: "medilog-schedule-monitoring",
  templateUrl: "./schedule-monitoring-v2.component.html",
  styleUrls: ["./schedule-monitoring-v2.component.scss"],
})
export class ScheduleMonitoringComponentV2 implements OnInit {
  legends = [
    {
      name: "Registro realizado dentro do horário previsto em escala (com tolerância máxima de 15 minutos para entradas/saídas antecipadas/atrasadas).",
      color: "#025A7F",
    },
    {
      name: "Registro realizado com horário diferente do previsto em escala e acima da tolerância aplicada.",
      color: "#ffff00",
    },
    { name: "Registro iniciado, jornada em andamento.", color: "#17ce17" },
    { name: "Registro não iniciado, jornada em andamento.", color: "#ff6600" },
    { name: "Registro não realizado.", color: "#ff0000" },
  ];
  isOpen = false;
  title: string;
  loading = false;

  healthUnits = {
    results: [{ name: "Selecione a Unidade", id: 0 }],
  };

  grids = {
    results: [
      {
        name: "Selecione a Grade",
        id: null,
      },
    ],
  };

  healthProfessionals: any = {
    results: [
      {
        name: "Todos os Profissionais",
        user_type: 0,
        email: 0,
        id: null,
      },
    ],
  };

  selectedUnit = {
    name: "Todas as Unidades",
    id: null,
  };

  selectedProfessional = {
    name: "Todos os Profissionais",
    user_type: 0,
    email: 0,
    id: null,
  };

  selectedGrid: any = {
    name: "Selecione a Grade",
    id: null,
  };

  modelDataInicio: any = null;

  // set(new Date(), {
  //   date: 1,
  //   hours: 0,
  //   minutes: 0,
  //   seconds: 0,
  // });

  pt = {
    firstDayOfWeek: 0,
    dayNames: [
      "Domingo",
      "Segunda Feira",
      "Terça Feira",
      "Quarta Feira",
      "Quinta Feira",
      "Sexta Feira",
      "Sábado",
    ],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    today: "Hoje",
    clear: "Limpar",
    dateFormat: "mm/dd/yy",
  };
  month: string = "Selecione o Mês";

  externalSchedule = [];
  internalSchedule = [];
  displayedColumns: string[] = [
    "img",
    "health_professional_name",
    "start_datetime",
    "end_datetime",
    "worked_hours",
    "adjusted_start_datetime",
    "adjusted_end_datetime",
    "adjusted_worked_hours",
    "origin",
    "action",
  ];
  ELEMENT_DATA = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  schedule;
  review;
  scheduleTable;
  greenProfessionals = [];
  autoSave = true;
  cols = [];
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = "Paginação ";
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private workShiftService: WorkShiftService,
    private healthUnitService: HealthUnitService,
    private healthProfessionalService: HealthProfessionalsService,
    private reviewService: ReviewService,
    private gridService: GridService,
    private scheduleService: ScheduleService,
    private consolidatedShiftService: ConsolidatedShiftService,
    private workSlotService: WorkSlotService,
    public dialog: MatDialog,
    @Inject(SCHEDULE_MONITORING_MODAL_TOKEN)
    private scheduleMonitoringModalComponent: TemplateRef<ScheduleMonitoringModalComponent>
  ) {
    document.getElementById("menu").click();
  }

  async ngOnInit() {
    this.cols = [
      { field: "nome", header: "Nome" },
      { field: "entrada", header: "Entrada" },
      { field: "saida", header: "Saida" },
      { field: "total", header: "Total" },
      { field: "acoes", header: "Ações" },
    ];
    this.title = localStorage.getItem("titulo");
    this.autoSave = localStorage.getItem("autoSave") === "true" ? true : false;
    await this.getHealthUnits();
  }

  getHealthUnits() {
    this.loading = true;
    const params = {
      health_unit_status: "enable",
    };
    this.healthUnitService.getHealthUnits(params).subscribe(async (data) => {
      this.healthUnits = data;

      this.healthUnits.results.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      this.healthUnits.results.unshift({ name: "Selecione a Unidade", id: 0 });
      this.loading = false;
    });

    this.selectedUnit = { name: "Selecione a Unidade", id: 0 };
  }

  //Busca as grades para o select
  async getGrid() {
    if (this.selectedUnit.id == 0) {
      this.grids?.results.push({ name: "Selecione a Grade", id: 0 });
      this.loading = false;
    } else {
      this.loading = true;
      await this.gridService
        .getGrids(this.selectedUnit.id)
        .subscribe(async (data) => {
          this.grids = data as any;
          this.grids.results.sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });
          this.grids.results.unshift({ name: "Selecione a Grade", id: 0 });
          this.loading = false;
        });
    }
  }

  async requestData() {
    this.externalSchedule = [];
    this.internalSchedule = [];
    this.scheduleTable = [];
    this.review = [];
    this.validateInputs();
    //monta a escala
    await this.buildSchedule();
  }

  async buildSchedule() {
    const dateFormat = this.modelDataInicio.toISOString().split("T");
    //Busca a escala
    this.loading = true;
    await this.scheduleService
      .getSchedule(
        this.selectedGrid.id,
        this.selectedUnit.id,
        dateFormat[0],
        true
      )
      .subscribe(
        async (data) => {
          if (!data) {
            this.openDialog(true, "Não foram encontrados registros");
            this.loading = false;
            return;
          } else {
            this.schedule = data;

            var month = new Date(
              this.schedule.month.replace("-", "/").replace("-", "/")
            );

            //Preenche a tabela de acordo com os turnos
            this.scheduleTable = this.getWeeksInMonth(
              month.getFullYear(),
              getMonth(month),
              this.selectedGrid.planned_workshifts
            );

            //Preenche quem fara cada turno
            await this.insertProfessionalsInTable();

            //Busca os registros de entrada
            let body: any = {
              health_unit_id: this.selectedUnit.id,
              date: dateFormat[0],
              grid_id: this.selectedGrid.id,
              filter_work_slot: true,
            };

            if (this.selectedProfessional.id)
              body.health_professional = this.selectedProfessional.id;

            await this.reviewService.getAllReview(body).subscribe(
              async (result) => {
                this.review = result;

                await this.checkPoint(this.review);
                this.loading = false;
              },
              (error) => {
                this.loading = false;
              }
            );
          }
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  validateInputs() {
    this.month = format(this.modelDataInicio, "LLLL", { locale: ptBR });

    if (this.selectedUnit.id === 0 || !this.selectedUnit) {
      this.openDialog(true, "Informe a Unidade.");
      this.loading = false;
      return;
    }

    if (!this.selectedGrid.id || this.selectedGrid.id === 0) {
      this.openDialog(true, "Informe a Grade.");
      this.loading = false;
      return;
    }
    if (!this.modelDataInicio) {
      this.openDialog(true, "Informe o mês.");
      this.loading = false;
      return;
    }
  }

  async publish(published: boolean, showDialog: boolean) {
    var arrayToPost = {
      schedule: this.schedule.id,
      work_slots: [],
      health_team: [],
      published: published || false,
      review: true,
    };
    var mesPlanejado = new Date(
      this.schedule.month.replace("-", "/").replace("-", "/")
    );
    var month =
      mesPlanejado.getMonth() + 1 <= 9
        ? "0" + (mesPlanejado.getMonth() + 1)
        : mesPlanejado.getMonth() + 1;
    var year = mesPlanejado.getFullYear();

    var arraySplit = [];

    for (var w = 0; w < this.scheduleTable.length; w++) {
      for (var sem = 0; sem < this.scheduleTable[w].semana.length; sem++) {
        for (
          var dia = 0;
          dia < this.scheduleTable[w].semana[sem].dias.length;
          dia++
        ) {
          if (this.scheduleTable[w].semana[sem].dias[dia].dia != 0) {
            var day;
            var dateBd = this.scheduleTable[w].semana[sem].realStartDate;
            if (this.scheduleTable[w].semana[sem].dias[dia].dia < 10) {
              day =
                "0" +
                this.scheduleTable[w].semana[sem].dias[dia].dia.toString();
            } else {
              day = this.scheduleTable[w].semana[sem].dias[dia].dia.toString();
            }

            var today = new Date(
              year + "-" + month + "-" + day + "T" + dateBd + "Z"
            );
            var splitHora = dateBd.split(":");
            var starts = this.scheduleTable[w].semana[sem].duration.split(":");
            var hh;
            if (splitHora[0] < 10) {
              hh = Number(splitHora[0].toString());
            } else {
              hh = splitHora[0];
            }
            today.setHours(hh, 0, 0);
            var jj;
            if (starts[0] < 10) {
              jj = Number(starts[0].toString());
            } else {
              jj = starts[0];
            }

            today.setHours(Number(today.getHours()) + Number(jj));
            var endDay = today.toLocaleTimeString();

            for (
              var prof = 0;
              prof <
              this.scheduleTable[w].semana[sem].dias[dia].professionais.length;
              prof++
            ) {
              if (
                this.scheduleTable[w].semana[sem].dias[dia].professionais[prof]
                  .health_professional_name != "" &&
                this.scheduleTable[w].semana[sem].dias[dia].professionais[prof]
                  .health_professional_name
              ) {
                arrayToPost.work_slots.push({
                  health_professional:
                    this.scheduleTable[w].semana[sem].dias[dia].professionais[
                      prof
                    ].health_professional_id,
                  replaced: null,
                  period: this.scheduleTable[w].semana[sem].periodo,
                  start_date: year + "-" + month + "-" + day, //+"T"+dateBd+"Z",
                  finalization_date: year + "-" + month + "-" + day, //+"T"+endDay+"Z",
                  planned_workshift: this.scheduleTable[w].semana[sem].id,
                  is_extra:
                    this.scheduleTable[w].semana[sem].dias[dia].professionais[
                      prof
                    ].is_extra || false,
                });
              }
            }
          }
        }
      }
    }
    if (!this.autoSave) {
      this.loading = true;
    }
    await this.workSlotService.sendWorkSlot(arrayToPost).subscribe(
      (data) => {
        this.loading = false;
        if (showDialog) {
          this.openDialog(
            false,
            published
              ? "Escala publicada com sucesso!"
              : "Escala salva com sucesso!"
          );
        }
        // this.requestData();
      },

      (error) => {
        this.loading = false;
        this.openDialog(true, "Ocorreu um erro, tente novamente!");
        return error;
      }
    );
  }

  openDialog(error: boolean, text: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: error ? "vermelhoPanel" : "verdePanel",
      data: { description: text },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  getWeeksInMonth(year, month, planned_workshifts) {
    const weeks = [],
      firstDate = new Date(year, month, 1),
      lastDate = new Date(year, month + 1, 0),
      numDays = lastDate.getDate();

    let dayOfWeekCounter = firstDate.getDay();

    for (let date = 1; date <= numDays; date++) {
      if (dayOfWeekCounter === 0 || weeks.length === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(date);
      dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
    }

    var semanas = weeks
      .filter((w) => !!w.length)
      .map((w) => ({
        start: w[0],
        end: w[w.length - 1],
        dates: w,
      }));

    var firstWeek = semanas[0];
    var lastWeek = semanas[semanas.length - 1];
    var concatFirstWeek = 7 - firstWeek.dates.length;
    var concatLasttWeek = 7 - lastWeek.dates.length;

    var arrayWeek = [];

    for (var s = 0; s < semanas.length; s++) {
      arrayWeek.push({ semana: [] });
      for (
        var turno = 0;
        turno < this.selectedGrid.planned_workshifts.length;
        turno++
      ) {
        var starts = planned_workshifts[turno].start_time.split(":");
        this.calculateHour(turno);

        const start_time = planned_workshifts[turno].start_time.substring(0, 5);
        const horaFim = planned_workshifts[turno].horaFim.substring(0, 5);

        arrayWeek[s].semana.push({
          id: planned_workshifts[turno].id,
          periodo: planned_workshifts[turno].name,
          periodoLabel: start_time + "-" + horaFim,
          dias: [],
          realStartDate: planned_workshifts[turno].start_time,
          duration: planned_workshifts[turno].duration,
        });

        for (var dia = 0; dia < semanas[s].dates.length; dia++) {
          arrayWeek[s].semana[arrayWeek[s].semana.length - 1].dias.push({
            dia: semanas[s].dates[dia],
            professionais: [],
          });

          for (
            var prof = 0;
            prof < planned_workshifts[turno].professionals_count;
            prof++
          ) {
            arrayWeek[s].semana[arrayWeek[s].semana.length - 1].dias[
              arrayWeek[s].semana[arrayWeek[s].semana.length - 1].dias.length -
                1
            ].professionais.push({ id: "" });
          }
        }
      }

      if (s == semanas.length - 1) {
        for (var i = 0; i < concatFirstWeek; i++) {
          for (var s = 0; s < arrayWeek[0].semana.length; s++) {
            arrayWeek[0].semana[s].dias.unshift({ dia: 0, professionais: [] });
          }
        }
        for (var i = 0; i < concatLasttWeek; i++) {
          for (var s = 0; s < arrayWeek[0].semana.length; s++) {
            arrayWeek[semanas.length - 1].semana[s].dias.push({
              dia: 0,
              professionais: [],
            });
          }
        }
        return arrayWeek;
      }
    }
  }

  insertProfessionalsInTable() {
    var slots = this.schedule.work_slots_review.lenght
      ? this.schedule.work_slots_review
      : this.schedule.work_slots;
    for (const slot of slots) {
      //O profissional para adicionar

      let prof = this.healthProfessionals.results.find(
        (profissional) =>
          profissional.health_professional_id === slot?.health_professional?.id
      );

      if (!prof) continue;

      const dia = parseInt(slot.start_date.slice(slot.start_date.length - 2));

      for (var mes = 0; mes < this.scheduleTable.length; mes++) {
        for (
          var semana = 0;
          semana < this.scheduleTable[mes].semana.length;
          semana++
        ) {
          for (
            var dias = 0;
            dias < this.scheduleTable[mes].semana[semana].dias.length;
            dias++
          ) {
            for (
              var profissional: number =
                this.scheduleTable[mes].semana[semana].dias[dias].professionais
                  .length - 1;
              profissional >= 0;
              profissional--
            ) {
              //verifica se o usuario esta escalado no slot
              const userInSlot =
                this.scheduleTable[mes].semana[semana].dias[dias].professionais[
                  profissional
                ].id === prof.health_professional_id;

              //verifica se o slot verificado esta disponivel
              const emptySlot =
                this.scheduleTable[mes].semana[semana].dias[dias].professionais[
                  profissional
                ].id === "";

              if (
                this.scheduleTable[mes].semana[semana].dias[dias].dia === dia &&
                this.scheduleTable[mes].semana[semana].periodo === slot.period
              ) {
                if (userInSlot) {
                  this.scheduleTable[mes].semana[semana].dias[
                    dias
                  ].professionais[profissional] = {
                    id: prof.health_professional_id,
                    health_professional_id: prof.health_professional_id,
                    health_professional_name: prof.name,
                    profile: prof.profile,
                    periodo: prof.periodo,
                    is_extra: slot.is_extra || false,
                  };

                  break;
                } else if (!userInSlot && emptySlot) {
                  this.scheduleTable[mes].semana[semana].dias[
                    dias
                  ].professionais[profissional] = {
                    id: prof.health_professional_id,
                    health_professional_id: prof.health_professional_id,
                    health_professional_name: prof.name,
                    profile: prof.profile,
                    periodo: prof.periodo,
                    is_extra: slot.is_extra || false,
                  };
                  break;
                } else if (slot.is_extra) {
                  this.scheduleTable[mes].semana[semana].dias[
                    dias
                  ].professionais.unshift({
                    id: prof.health_professional_id,
                    health_professional_name: prof.name,
                    health_professional_id: prof.health_professional_id,
                    profile: prof.profile,
                    periodo: prof.periodo,
                    is_extra: slot.is_extra || false,
                  });
                  break;
                }
              }
            }
          }
        }
      }
    }
  }

  checkPoint(profSearchData: Review[], changed?: boolean) {
    var professionalToRemove = [];

    for (const professionalCheck of profSearchData) {
      professionalCheck.origin =
        professionalCheck.adjusted_start_datetime && !professionalCheck.is_split
          ? "Gestor"
          : professionalCheck.adjusted_start_datetime &&
            professionalCheck.is_split
          ? "Split"
          : "APP";

      const inicialStartDatetime = new Date(
        professionalCheck.adjusted_start_datetime
          ? professionalCheck.adjusted_start_datetime
          : professionalCheck.start_datetime
      ).toISOString();

      let inicialEndDatetime = professionalCheck.adjusted_end_datetime
        ? professionalCheck.adjusted_end_datetime
        : professionalCheck.end_datetime
        ? professionalCheck.end_datetime
        : null;

      const dayStart = Number(
        formatDate(inicialStartDatetime).split(" ")[0].split("/")[0]
      );
      let dayEnd = dayStart;

      if (inicialEndDatetime) {
        dayEnd = Number(
          formatDate(inicialEndDatetime).split(" ")[0].split("/")[0]
        );
      }

      if (
        professionalCheck.condition === "INPROGRESS" ||
        (professionalCheck.condition === "LONG_SHIFT" &&
          !professionalCheck.end_datetime &&
          !professionalCheck.adjusted_end_datetime)
      ) {
        this.greenProfessionals.push(professionalCheck);
      }
      for (var mes = 0; mes < this.scheduleTable.length; mes++) {
        for (
          var semana = 0;
          semana < this.scheduleTable[mes].semana.length;
          semana++
        ) {
          if (
            professionalCheck.period ===
            this.scheduleTable[mes].semana[semana].periodo
          ) {
            for (
              var dias = 0;
              dias < this.scheduleTable[mes].semana[semana].dias.length;
              dias++
            ) {
              if (
                Number(
                  this.scheduleTable[mes].semana[semana].dias[dias].dia
                ) === dayStart
              ) {
                for (
                  let profissional =
                    this.scheduleTable[mes].semana[semana].dias[dias]
                      .professionais.length - 1;
                  profissional >= 0;
                  profissional--
                ) {
                  if (
                    !this.scheduleTable[mes].semana[semana].dias[
                      dias
                    ].professionais.find(
                      (prof) =>
                        prof.id === professionalCheck.health_professional_id ||
                        prof.health_professional_id ===
                          professionalCheck.health_professional_id
                    ) &&
                    !this.scheduleTable[mes].semana[semana].dias[
                      dias
                    ].professionais.find((prof) => !prof.start_time) &&
                    changed
                  ) {
                    let prof = this.healthProfessionals.results.find(
                      (profissional) =>
                        profissional.health_professional_id ===
                        professionalCheck.health_professional_id
                    );

                    this.scheduleTable[mes].semana[semana].dias[
                      dias
                    ].professionais.unshift({
                      id: prof.health_professional_id,
                      health_professional_name: prof.name,
                      profile: prof.profile,
                      periodo: prof.periodo,
                      is_extra: true,
                    });
                  }

                  if (
                    changed === true
                      ? !this.scheduleTable[mes].semana[semana].dias[dias]
                          .professionais[profissional].start_time
                      : this.scheduleTable[mes].semana[semana].dias[dias]
                          .professionais[profissional].id ===
                        professionalCheck.health_professional_id
                  ) {
                    let profSearch = this.healthProfessionals.results.find(
                      (profissional) =>
                        profissional.health_professional_id ===
                        professionalCheck.health_professional_id
                    );

                    this.scheduleTable[mes].semana[semana].dias[
                      dias
                    ].professionais[profissional] = {
                      ...professionalCheck,

                      health_professional_name: profSearch.name,
                      profile: profSearch.profile,
                      periodo: profSearch.periodo,
                      regional_council_document_number:
                        profSearch.regional_council_document_number,
                      start_time: professionalCheck.adjusted_start_datetime
                        ? getHoursMinuts(
                            professionalCheck.adjusted_start_datetime
                          )
                        : professionalCheck.start_datetime
                        ? getHoursMinuts(professionalCheck.start_datetime)
                        : null,
                      end_time: professionalCheck.adjusted_end_datetime
                        ? getHoursMinuts(
                            professionalCheck.adjusted_end_datetime
                          )
                        : professionalCheck.end_datetime
                        ? getHoursMinuts(professionalCheck.end_datetime)
                        : null,
                      total: professionalCheck.adjusted_worked_hours
                        ? professionalCheck.adjusted_worked_hours
                        : professionalCheck.worked_hours,
                      id: professionalCheck.source_id
                        ? professionalCheck.source_id
                        : professionalCheck.id,
                      health_unit_id: this.selectedUnit.id,
                      health_unit_name: this.selectedUnit.name,
                      is_extra:
                        this.scheduleTable[mes].semana[semana].dias[dias]
                          .professionais[profissional].is_extra,
                    };
                    professionalToRemove.push(professionalCheck);
                    this.internalSchedule.push(professionalCheck);
                    // this.externalSchedule.push(professionalCheck);
                    // this.dataSource = new MatTableDataSource(
                    //   this.externalSchedule
                    // );
                    if (changed) {
                      professionalToRemove.map((profe) => {
                        // this.externalSchedule = this.externalSchedule.filter(
                        //   (registerFiltered) =>
                        //     (registerFiltered.source_id != null
                        //       ? registerFiltered.source_id != profe.source_id
                        //       : registerFiltered.id != profe.id)
                        // );
                        // const find = this.externalSchedule.find(
                        //   (registerFiltered) =>
                        //     registerFiltered.source_id === profe.source_id &&
                        //     registerFiltered.id === profe.id &&
                        //     registerFiltered.period === profe.period
                        // );
                        // if (find) {

                        this.externalSchedule = this.externalSchedule.filter(
                          (schedule) => professionalCheck.id != schedule.id
                        );
                        // professionalCheck.source_id
                        //   ? this.externalSchedule.filter(
                        //       (schedule) =>
                        //         professionalCheck.source_id !=
                        //         schedule.source_id
                        //     )
                        //   :

                        this.dataSource = new MatTableDataSource(
                          this.externalSchedule
                        );
                        this.dataSource.paginator = this.paginator;
                        // }
                      });
                      return;
                    }
                  } else {
                    if (
                      !this.scheduleTable[mes].semana[semana].dias[
                        dias
                      ].professionais.find(
                        (prof) =>
                          prof.id ===
                            professionalCheck.health_professional_id ||
                          prof.health_professional_id ===
                            professionalCheck.health_professional_id
                      ) &&
                      !changed
                    ) {
                      // const find = this.externalSchedule.find(
                      //   (registerFiltered) =>
                      //     registerFiltered.source_id ===
                      //       professionalCheck.source_id &&
                      //     registerFiltered.health_professional_id ===
                      //       professionalCheck.health_professional_id &&
                      //     registerFiltered.period === professionalCheck.period
                      // );

                      // if (true) {
                      let profSearch = this.healthProfessionals.results.find(
                        (profissional) =>
                          profissional.health_professional_id ===
                          professionalCheck.health_professional_id
                      );

                      if (!profSearch) continue;

                      this.externalSchedule = this.externalSchedule.filter(
                        (schedule) => professionalCheck.id != schedule.id
                      );

                      this.externalSchedule.push({
                        ...professionalCheck,
                        photo: profSearch.photo,
                      });

                      this.dataSource = new MatTableDataSource(
                        this.externalSchedule
                      );
                      this.dataSource.paginator = this.paginator;
                      continue;
                      // }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  calculateHour(position) {
    if (
      this.selectedGrid.planned_workshifts[position].start_time != "" &&
      this.selectedGrid.planned_workshifts[position].duration != ""
    ) {
      this.selectedGrid.planned_workshifts[position].horaFim = sumTimes(
        this.selectedGrid.planned_workshifts[position].start_time,
        this.selectedGrid.planned_workshifts[position].duration
      );
    }
  }

  changeGrid() {
    this.getProfissionais(
      this.selectedGrid.occupation_area,
      this.selectedGrid.areas_of_expertise_id
    );
  }

  async getProfissionais(
    occupation_area?: string,
    areas_of_expertise?: string[]
  ) {
    this.loading = true;
    if (occupation_area) {
      await this.healthProfessionalService
        .getCompanyHealthProfessionalsCategory(
          occupation_area,
          areas_of_expertise
        )
        .subscribe((data) => {
          this.healthProfessionals = data;

          this.healthProfessionals.results.sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          // this.healthProfessionals.results.map((professional) => {
          //   this.healthUnitsProfissionais.push(professional);
          // });

          this.healthProfessionals.results.unshift({
            name: "Todos os Profissionais",
            user_type: 0,
            email: 0,
            id: null,
          });

          // if (this.selectedGrade.id) {
          //   this.requestData()
          // }
          this.loading = false;
        });
    } else {
      await this.healthProfessionalService
        .getCompanyHealthProfessionalsCategory()
        .subscribe((data) => {
          this.healthProfessionals = data;

          this.healthProfessionals.results.sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          // this.healthProfessionals.results.map((professional) => {
          //   this.healthUnitsProfissionais.push(professional);
          // });

          this.healthProfessionals.results.unshift({
            name: "Todos os Profissionais",
            user_type: 0,
            email: 0,
            id: null,
          });
          this.loading = false;
        });
    }
  }

  formatedDate(date) {
    return formatDate(date);
  }

  getActiveDay(dia) {
    if (this.selectedGrid) {
      if (dia == "0") {
        return this.selectedGrid.days_of_week.sunday;
      } else if (dia == "1") {
        return this.selectedGrid.days_of_week.monday;
      } else if (dia == "2") {
        return this.selectedGrid.days_of_week.tuesday;
      } else if (dia == "3") {
        return this.selectedGrid.days_of_week.wednesday;
      } else if (dia == "4") {
        return this.selectedGrid.days_of_week.thursday;
      } else if (dia == "5") {
        return this.selectedGrid.days_of_week.friday;
      } else {
        return this.selectedGrid.days_of_week.saturday;
      }
    } else {
      return;
    }
  }

  //transforma o dia em string do dia da semana
  getDay(day) {
    if (day == "0") {
      return "Domingo";
    } else if (day == "1") {
      return "Segunda";
    } else if (day == "2") {
      return "Terça";
    } else if (day == "3") {
      return "Quarta";
    } else if (day == "4") {
      return "Quinta";
    } else if (day == "5") {
      return "Sexta";
    } else {
      return "Sabado";
    }
  }

  //transforma o dia em string quando for menor que 10
  formatDay(day) {
    if (day == 0) {
      return "";
    } else {
      if (day < 10) {
        return "0" + day.toString();
      } else {
        return day;
      }
    }
  }

  countReview(day, period) {
    let reviewCount = 0;
    for (const ex of this.dataSource.data) {
      const dayStart = Number(
        ex.adjusted_start_datetime
          ? this.formatedDate(ex.adjusted_start_datetime)
              .split(" ")[0]
              .split("/")[0]
          : this.formatedDate(ex.start_datetime)?.split(" ")[0]?.split("/")[0]
      );
      if (Number(dayStart) === Number(day)) {
        if (ex.period === period) {
          reviewCount++;
        } else if (ex.period === null) {
          return ex;
        }
      }
    }

    return reviewCount;
  }

  checkStatus(prof, dia, periodo) {
    const startTimePeriod = periodo.periodoLabel.split("-")[0];

    var year = this.schedule.month.split("-")[0];
    var month = this.schedule.month.split("-")[1];
    const turnDate = new Date(
      year +
        "-" +
        month +
        "-" +
        (Number(dia) <= 9 ? "0" + Number(dia) : Number(dia)) +
        "T" +
        startTimePeriod
    );

    let [horas, minutos, segundos] = periodo.duration.split(":").map(Number);
    const endTurnDate = new Date(
      year +
        "-" +
        month +
        "-" +
        (Number(dia) <= 9 ? "0" + Number(dia) : Number(dia)) +
        "T" +
        startTimePeriod
    );
    // Adicionando horas, minutos e segundos à data
    endTurnDate.setHours(endTurnDate.getHours() + horas);
    endTurnDate.setMinutes(endTurnDate.getMinutes() + minutos);
    endTurnDate.setSeconds(endTurnDate.getSeconds() + segundos);

    const hasGreenProfessional = this.greenProfessionals.find(
      (professional) =>
        professional.health_professional_name ===
          prof.health_professional_name &&
        isAfter(
          new Date(),
          subHours(
            new Date(
              professional.adjusted_start_datetime
                ? professional.adjusted_start_datetime
                : professional.start_datetime
            ),
            3
          )
        )
    );

    const profDate =
      prof.adjusted_start_datetime || prof.start_datetime
        ? new Date(
            this.formatedDate(
              prof.adjusted_start_datetime ?? prof.start_datetime
            )
          )
        : null;
    if (prof.condition === "SUCCESS") {
      return "color-success";
    } else if (prof.condition === "OUTSIDE") {
      return "color-outside";
    } else if (
      prof.condition === "INPROGRESS" ||
      (prof.condition === "LONG_SHIFT" &&
        !prof.end_datetime &&
        !prof.adjusted_end_datetime)
    ) {
      return "color-inProgress";
    } else if (
      isAfter(new Date(), endTurnDate) &&
      !prof.condition &&
      !hasGreenProfessional
    ) {
      return "color-missing";
    } else if (
      isAfter(new Date(), turnDate) &&
      isAfter(endTurnDate, new Date()) &&
      !profDate &&
      !prof.condition
    ) {
      return "color-missing-day";
    } else if (
      isAfter(new Date(), turnDate) &&
      isAfter(turnDate, subMinutes(profDate, 60)) &&
      !prof.condition &&
      hasGreenProfessional
    ) {
      return "color-inProgress";
    } else if (prof.end === null) {
      return "color-noReport";
    }
  }
  //Pega o primeiro e ultimo nome do profissional
  getNameProf(name: string) {
    if (name) {
      const names = name.split(" ");
      if (names[0] === names[names.length - 1]) {
        return names[0];
      } else {
        return names[0] + " " + names[names.length - 1];
      }
    } else {
      return name;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  changeAutoSave() {
    localStorage.setItem("autoSave", JSON.stringify(this.autoSave));
  }

  async removeRegister(registro: ConsolidatedShift) {
    this.openDialogDisable("Deseja desativar o registro?", registro);

    if (this.autoSave) {
      await this.publish(false, false);
    }
  }
  openDialogDisable(texto, valor): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto, enableCancel: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "0") {
        var query = {
          disable_work_shift: true,
        };

        this.loading = true;

        this.workShiftService
          .disableWorkShift(query, valor.source_id ? valor.source_id : valor.id)
          .subscribe(
            (data) => {
              if (data.id != "") {
                this.externalSchedule = valor.source_id
                  ? this.externalSchedule.filter(
                      (schedule) =>
                        valor.source_id != schedule.id &&
                        valor.source_id != schedule.source_id
                    )
                  : this.externalSchedule.filter(
                      (schedule) => valor.id != schedule.id
                    );

                this.dataSource = new MatTableDataSource(this.externalSchedule);
                this.dataSource.paginator = this.paginator;
                this.openDialog(false, "Desativado com sucesso.");
              }

              this.loading = false;

              return true;
            },
            (error) => {
              this.loading = false;
              return error;
            }
          );
      } else {
      }
    });
  }

  async editProf(
    registro,
    dayGrid?,
    dia?,
    positionDia?,
    positionBlock?,
    positionPeriodo?,
    semana?
  ) {
    if (registro.period || registro.source_id) {
      await this.openEditDialog(
        {
          ...registro,
          adjusted_start_datetime: registro.is_split
            ? registro.start_datetime
            : registro.adjusted_start_datetime,
          adjusted_end_datetime: registro.is_split
            ? registro.end_datetime
            : registro.adjusted_end_datetime,
        },
        registro.source_id ? registro.source_id : registro.id
      );
    } else {
      await this.openDialogRegistro(
        dayGrid,
        dia,
        positionDia,
        positionBlock,
        positionPeriodo,
        semana,
        registro.id,
        registro
      );
    }
  }

  openEditDialog(register, prof_id) {
    let entryManual: string = "";
    let entryManualDate: Date = null;
    let leaveManual: string = "";
    let leaveManualDate: Date = null;
    let allHours: string = "";
    let manualAllHours: string = "";
    let entry: string = "__ /__ /____ __ : __";
    let leave: string = "__ /__ /____ __ : __";
    if (register.adjusted_start_datetime) {
      entryManual =
        // DD/MM/YYYY HH:MM:SS
        this.formatedDate(register.adjusted_start_datetime).split(" ")[0] +
        " - " +
        this.formatedDate(register.adjusted_start_datetime).split(" ")[1];
      entryManualDate = new Date(
        this.formatedDate(register.adjusted_start_datetime)
      );
    }

    if (register.adjusted_end_datetime) {
      leaveManual =
        this.formatedDate(register.adjusted_end_datetime).split(" ")[0] +
        " - " +
        this.formatedDate(register.adjusted_end_datetime).split(" ")[1];
      leaveManualDate = new Date(
        this.formatedDate(register.adjusted_end_datetime)
      );
    }

    if (register.worked_hours) {
      allHours = formatHour(register.worked_hours);
    }

    if (register.adjusted_worked_hours) {
      manualAllHours = formatHour(register.adjusted_worked_hours);
    }

    if (register.start_datetime) {
      entry =
        this.formatedDate(register.start_datetime).split(" ")[0] +
        " - " +
        this.formatedDate(register.start_datetime).split(" ")[1];
    }

    if (register.end_datetime) {
      leave =
        this.formatedDate(register.end_datetime).split(" ")[0] +
        " - " +
        this.formatedDate(register.end_datetime).split(" ")[1];
    }

    let pro = {
      id: register.source_id ? register.source_id : register.id,
      image: register.health_professional_photo,
      doctor: register.health_professional_name,
      clinic: this.selectedUnit.name,
      health_professional_id: register.health_professional_id,

      entry: entry,
      manualentry: entryManual,
      manualentryDate: entryManualDate,
      leave: leave,
      manualeave: leaveManual,
      manualeaveDate: leaveManualDate,
      hours: allHours,
      manualhours: manualAllHours,
    };

    const dialogRef = this.dialog.open(EditRegistroComponent, {
      width: "1090px",
      height: "347px",
      panelClass: "azulPanel",
      data: {
        noShow: true,
        description: { ...pro },
        selectUnity: this.selectedUnit.id,
        prof_id: register.health_professional_id,
        occupation_area_id: this.selectedGrid.occupation_area,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        // const dialogRef = this.dialog.open(DialogComponent, {
        //   width: "570px",
        //   height: "170px",
        //   panelClass: "vermelhoPanel",
        //   data: {
        //     description: "Deseja salvar as alterações?",
        //     enableCancel: true,
        //   },
        // });

        // dialogRef.afterClosed().subscribe(async (result) => {
        //   if (result == "0") {
        if (this.autoSave) {
          await this.publish(false, false);
        }
        // }
        // await this.requestData();
        // });
      }
    });
  }

  openDialogRegistro(
    dayGrid,
    dia,
    positionDia,
    positionBlock,
    positionPeriodo,
    semana,
    prof_id,
    registro
  ) {
    var mesPlanejado = new Date(
      this.schedule.month.replace("-", "/").replace("-", "/")
    );
    var month = mesPlanejado.getMonth();
    var year = mesPlanejado.getFullYear();

    const start_time = new Date(Number(year), Number(month), Number(dia));
    const end_time = new Date(Number(year), Number(month), Number(dia));

    const dialogRef = this.dialog.open(DetailRegistroComponent, {
      width: "1000px",
      height: "200px",
      panelClass: "azulPanel",
      disableClose: true,
      data: {
        description: {},
        unit: this.selectedUnit.id,
        grid: this.selectedGrid.id,
        period: dayGrid.period,
        day: dia,
        start_time: start_time,
        end_time: end_time,
        prof_id: prof_id,
        occupation_area_id: this.selectedGrid.occupation_area,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const dialogRefs = this.dialog.open(DialogComponent, {
          width: "570px",
          height: "170px",
          panelClass: "vermelhoPanel",
          data: {
            description: "Tem certeza que deseja adicionar esse registro?",
            enableCancel: true,
          },
        });

        dialogRefs.afterClosed().subscribe((results) => {
          if (results == "0") {
            /*abrir modal*/
            this.cols = [
              { field: "doctor", header: "Profissionais de saúde" },
              { field: "clinic", header: "Unidade" },
              { field: "entry", header: "Entrada" },
              { field: "manualentry", header: "Entrada Ajustada" },
              { field: "leave", header: "Saída" },
              { field: "manualeave", header: "Saída Ajustada" },
              { field: "hours", header: "Tempo" },
              { field: "manualhours", header: "Tempo Ajustado" },
              { field: "adjustedat", header: "Ajustado em" },
              { field: "action", header: "" },
            ];

            this.consolidatedShiftService
              .createConsolidedShift(result)
              .subscribe(
                async (data) => {
                  await this.addProfessional(
                    dayGrid,
                    dia,
                    positionDia,
                    positionBlock,
                    positionPeriodo,
                    semana,
                    data.lenght
                      ? data[0].health_professional
                      : data.health_professional,
                    prof_id,
                    data
                  );
                },
                (error) => {
                  this.openDialog(
                    true,
                    "Não foi possível realizar o registro!"
                  );
                  this.loading = false;
                  return;
                }
              );
          } else {
          }
        });
      }
    });
  }

  async addProfessional(
    dayGrid,
    dia,
    positionDia,
    positionBlock,
    positionPeriodo,
    semana,
    health_professional_id,
    last_prof_id,
    data
  ) {
    var unique = false;

    if (dia != "") {
      unique = true;
    }

    let profSearch = this.healthProfessionals.results.find(
      (profissional) =>
        profissional.health_professional_id === health_professional_id
    );

    const result = {
      ...profSearch,
      dia: dia,

      ...profSearch,
      id: health_professional_id,
      health_professional_name: profSearch.name,
    };

    for (var sem = 0; sem < this.scheduleTable.length; sem++) {
      for (
        var dias = 0;
        dias < this.scheduleTable[sem].semana[positionPeriodo].dias.length;
        dias++
      ) {
        if (
          this.scheduleTable[sem].semana[positionPeriodo].dias[dias].dia == dia
        ) {
          let filterIndex =
            this.scheduleTable[sem].semana[positionPeriodo].dias[
              dias
            ].professionais.findIndex(
              (element) => element.id === "" && !element.health_professional_id
            ) ||
            this.scheduleTable[sem].semana[positionPeriodo].dias[
              dias
            ].professionais.findIndex(
              (element) =>
                element.id === last_prof_id &&
                element.health_professional_id != ""
            );

          if (filterIndex === -1) filterIndex = 0;

          this.scheduleTable[sem].semana[positionPeriodo].dias[
            dias
          ].professionais[filterIndex] = result;
          break;
        }
      }
    }
    this.loading = false;
    data = data.lenght
      ? data.map((element) => {
          return {
            ...element,
            health_professional_id: element.health_professional,
          };
        })
      : [{ ...data, health_professional_id: data.health_professional }];

    await this.checkPoint(data, false);

    if (this.autoSave) {
      await this.publish(true, false);
      // await this.requestData();
    }
  }

  async changeProf(registro: any) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: {
        description: "Deseja adicionar esse registro na escala?",
        enableCancel: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result == "0") {
        await this.checkPoint([registro], true);
        if (this.autoSave) {
          await this.publish(false, false);
        }
        return;
      }
    });
  }

  openModalScheduleMonitoring(day, period) {
    const review = this.externalSchedule.filter((ex) => {
      const dayStart = Number(
        ex.adjusted_start_datetime
          ? formatDate(ex.adjusted_start_datetime).split(" ")[0].split("/")[0]
          : formatDate(ex.start_datetime).split(" ")[0].split("/")[0]
      );

      if (Number(dayStart) === Number(day)) {
        if (ex.period === period) {
          return ex;
        } else if (ex.period === null) {
          return ex;
        }
      }
    });

    const dialogRef = this.dialog.open(this.scheduleMonitoringModalComponent, {
      width: "1150px",
      height: "450px",
      panelClass: "azulPanel",
      //disableClose: true,
      data: { externalSchedule: review },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.action === "CHANGE") {
        this.changeProf(result);
      } else if (result?.action === "EDIT") {
        this.editProf(result);
      } else if (result?.action === "REMOVE") {
        this.removeRegister(result);
      }
    });
  }

  removeProf(professionalCheck, dia, periodo) {
    var texto = "Deseja remover esse registro?";
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto, enableCancel: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "0") {
        let prof = this.healthProfessionals.results.find(
          (profissional) =>
            profissional.health_professional_id ===
            professionalCheck.health_professional_id
        );

        for (var mes = 0; mes < this.scheduleTable.length; mes++) {
          for (
            var semana = 0;
            semana < this.scheduleTable[mes].semana.length;
            semana++
          ) {
            for (
              var dias = 0;
              dias < this.scheduleTable[mes].semana[semana].dias.length;
              dias++
            ) {
              if (
                Number(
                  this.scheduleTable[mes].semana[semana].dias[dias].dia
                ) === dia
              ) {
                for (
                  var profissional: number =
                    this.scheduleTable[mes].semana[semana].dias[dias]
                      .professionais.length - 1;
                  profissional >= 0;
                  profissional--
                ) {
                  // const hasProf = this.scheduleTable[mes].semana[semana].dias[
                  //   dias
                  // ].professionais.find(
                  //   (prof) => prof.id === prof.health_professional_id
                  // );

                  if (
                    this.scheduleTable[mes].semana[semana].dias[dias]
                      .professionais[profissional].health_professional_id ===
                      prof.health_professional_id &&
                    this.scheduleTable[mes].semana[semana].dias[dias]
                      .professionais[profissional].period ===
                      professionalCheck.period
                  ) {
                    this.scheduleTable[mes].semana[semana].dias[
                      dias
                    ].professionais[profissional] = {
                      id: "",
                      // health_professional_name: prof.name,
                      // profile: prof.profile,
                      // period: professionalCheck.period,
                    };

                    const data = {
                      ...professionalCheck,
                      photo: prof.photo,
                    };

                    this.externalSchedule.push(data as any);

                    this.dataSource = new MatTableDataSource(
                      this.externalSchedule
                    );
                    this.dataSource.paginator = this.paginator;
                  }
                }
              }
            }
          }
        }
      }
    });
  }
}
