import { Component, OnInit } from "@angular/core";
import { IClinicReportModel } from "src/app/models/clinic-report.model";
import { HealthUnitService } from "src/app/_services/health-unit.service";

import { Router, ActivatedRoute } from "@angular/router";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DialogComponent } from "../dialog/dialog.component";
import { MatDialog } from "@angular/material";
import { OccupationFieldService } from "src/app/_services/occupation-fied.service";
import { UserInfoService } from "src/app/_services/user-info.service";
import { CitiesService } from "src/app/_services/cities.service";

export class OptionObject {
  label: string;
  value: string;
}

declare var AzureStorage: any;

const ELEMENT_DATA: IClinicReportModel[] = [];

@Component({
  selector: "medlog-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  id: any;
  private sub: any;
  loading = false;
  ocupation: any;
  area_expertise: any = {
    results: [],
  };
  selectedCategory: any;
  selectedAreaExpertise: any;
  estado: any;
  estadoDocument: any;
  cidade: any;
  lblFoto;
  lblCRM;
  picProfile: any;
  picProfileName: string;
  picCrm: any;
  picCrmName: string;
  blobService: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private healthUnitService: HealthUnitService,
    routeParams: ActivatedRoute,
    public dialog: MatDialog,
    private readonly healthProfessionalsService: HealthProfessionalsService,
    private occupationFieldService: OccupationFieldService,
    private userInfoService: UserInfoService,
    private citiesService: CitiesService
  ) {}

  cols: any[];
  docs: any[];
  colsContract: any[];
  contracts: any[];
  doctor;
  title;
  cidadeCombo: any = {
    results: [],
  };

  stateCombo: any[] = [
    { label: "Estado", value: null },
    { label: "AC", value: "AC" },
    { label: "AL", value: "AL" },
    { label: "AP", value: "AP" },
    { label: "AM", value: "AM" },
    { label: "BA", value: "BA" },
    { label: "CE", value: "CE" },
    { label: "DF", value: "DF" },
    { label: "ES", value: "ES" },
    { label: "GO", value: "GO" },
    { label: "MA", value: "MA" },
    { label: "MT", value: "MT" },
    { label: "MS", value: "MS" },
    { label: "MG", value: "MG" },
    { label: "PA", value: "PA" },
    { label: "PB", value: "PB" },
    { label: "PR", value: "PR" },
    { label: "PE", value: "PE" },
    { label: "PI", value: "PI" },
    { label: "RJ", value: "RJ" },
    { label: "RN", value: "RN" },
    { label: "RS", value: "RS" },
    { label: "RO", value: "RO" },
    { label: "RR", value: "RR" },
    { label: "SC", value: "SC" },
    { label: "SP", value: "SP" },
    { label: "SE", value: "SE" },
    { label: "TO", value: "TO" },
  ];

  form: FormGroup = new FormGroup({
    nome: new FormControl("", Validators.required),
    cpf: new FormControl("", Validators.required),
    rg: new FormControl("", Validators.required),
    crm: new FormControl("", Validators.required),
    rua: new FormControl(""),
    numero: new FormControl(""),
    dh_nascimento: new FormControl(""),
    cep: new FormControl(""),
    complemento: new FormControl(""),
    cidade: new FormControl(""),
    estado: new FormControl(""),
    estadoDocument: new FormControl(""),
    telefone: new FormControl("", Validators.required),
    email: new FormControl("", Validators.required),
    selectedCategory: new FormControl("", Validators.required),
    selectedAreaExpertise: new FormControl(""),
  });

  ngOnInit() {
    this.blobService = AzureStorage.Blob.createBlobService(
      "DefaultEndpointsProtocol=https;AccountName=mediclog;AccountKey=6GYe4m9oPynRROntIhDoGfdnur4HhQlYg8+8oDzRDlyzd13KLmbRzmSw2q67QAtQBX3x+WO8CYS9ti8MXHca4g==;EndpointSuffix=core.windows.net"
    );
    this.blobService.listContainersSegmented(null, function (error, results) {
      if (error) {
        // List container error
      } else {
        for (var i = 0, container; (container = results.entries[i]); i++) {}
      }
    });

    this.lblFoto = "Foto perfil";
    this.lblCRM = "Foto documento conselho";

    this.cidadeCombo.results = [{ label: "Cidade", value: null }];

    this.title = localStorage.getItem("titulo");

    this.loading = true;

    this.getProfessional();

    this.cols = [
      { field: "document", header: "Documento" },
      { field: "action", header: "Ação" },
    ];
    this.colsContract = [
      { field: "document", header: "Documento" },
      { field: "action", header: "Ação" },
    ];

    this.contracts = [{ document: "CONTRATO 1", date: "10/10/2010" }];

    this.getOcupation();
  }

  addProfessional() {
    this.router.navigate(["/home/addprofessional"]);
  }

  getOcupation() {
    this.occupationFieldService.getOccupation().subscribe((data) => {
      this.ocupation = data;

      this.ocupation.results.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.ocupation.results.unshift({
        name: "Selecione",
        id: null,
      });
      this.loading = false;
    });
  }

  getAreasExpertise() {
    this.loading = true;
    if (this.selectedCategory.id) {
      this.occupationFieldService
        .getOccupationById(this.selectedCategory.id)
        .subscribe((data) => {
          this.area_expertise.results = data.areas_of_expertise;

          this.area_expertise.results.sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          this.area_expertise.results.unshift({
            name: "Selecione",
            id: null,
          });

          if (this.doctor.profile.area_of_expertise) {
            const area = this.area_expertise.results.find(
              (data) => data.name === this.doctor.profile.area_of_expertise
            );
            // this.form.controls.selectedAreaExpertise.setValue(area.id)
            this.selectedAreaExpertise = area;
          }
          this.loading = false;
        });
    } else {
      this.area_expertise.results = [
        {
          name: "Selecione a Categoria",
          id: null,
        },
      ];
    }
    this.loading = false;
  }

  getProfessional() {
    this.sub = this.route.params.subscribe((params) => {
      this.healthProfessionalsService
        .getProfessionals(params.id)
        .subscribe((data) => {
          this.form.controls.crm.setValue("");
          this.doctor = data;
          if (this.doctor.name)
            this.form.controls.nome.setValue(this.doctor.name);
          if (this.doctor.profile.cpf)
            this.form.controls.cpf.setValue(this.doctor.profile.cpf);
          if (this.doctor.profile.rg)
            this.form.controls.rg.setValue(this.doctor.profile.rg);
          if (this.doctor.profile.regional_council_document_number)
            this.form.controls.crm.setValue(
              this.doctor.profile.regional_council_document_number
            );

          if (this.doctor.profile.address)
            this.form.controls.rua.setValue(this.doctor.profile.address);
          if (this.doctor.profile.address_number)
            this.form.controls.numero.setValue(
              this.doctor.profile.address_number
            );

          if (this.doctor.profile.date_of_birth) {
            const [ano, mes, dia] =
              this.doctor.profile.date_of_birth.split("-");
            this.form.controls.dh_nascimento.setValue(
              dia + "/" + mes + "/" + ano
            );
          }

          if (this.doctor.profile.zip_code)
            this.form.controls.cep.setValue(this.doctor.profile.zip_code);

          if (this.doctor.profile.address_complement)
            this.form.controls.complemento.setValue(
              this.doctor.profile.address_complement
            );
          // this.form.controls.cidade.setValue(this.doctor.profile.city);

          if (this.doctor.profile.cel_phone) {
            if (
              this.doctor.profile.cel_phone[0] +
                this.doctor.profile.cel_phone[1] ===
              "55"
            ) {
              this.form.controls.telefone.setValue(
                this.doctor.profile.cel_phone.substring(
                  2,
                  this.doctor.profile.cel_phone.length
                )
              );
              // this.form.controls.telefone.setValue(this.doctor.profile.cel_phone.substring(2, this.doctor.profile.cel_phone.length - 1));
            } else {
              this.form.controls.telefone.setValue(
                this.doctor.profile.cel_phone
              );
            }
          }
          if (this.doctor.email)
            this.form.controls.email.setValue(this.doctor.email);

          if (this.doctor.profile.occupation_area) {
            const category = this.ocupation.results.find(
              (occupation) =>
                occupation.name.toUpperCase() ===
                this.doctor.profile.occupation_area.toUpperCase()
            );

            this.form.controls.selectedCategory.setValue(category.id);
            this.selectedCategory = category;
          }
          if (this.doctor.profile.state) {
            const stated = this.stateCombo.find(
              (state) =>
                state?.value?.toUpperCase() ===
                this.doctor?.profile?.state.toUpperCase()
            );
            this.form.controls.estado.setValue(
              this.doctor?.profile?.state.toUpperCase()
            );
            this.estado = stated;
          }

          if (this.doctor.profile.regional_council_state) {
            const stated = this.stateCombo.find(
              (state) =>
                state?.value?.toUpperCase() ===
                this.doctor?.profile?.regional_council_state.toUpperCase()
            );
            this.form.controls.estadoDocument.setValue(
              this.doctor?.profile?.regional_council_state.toUpperCase()
            );
            this.estadoDocument = stated;
          }

          this.loading = false;

          this.docs = [
            { document: "FOTO", action: this.doctor["profile"]["photo"] },
            {
              document: "CRM",
              action:
                this.doctor["profile"]["regional_council_document_picture"],
            },
          ];

          //Reseta os files

          this.picProfile = null;
          this.picProfileName = null;

          this.picCrm = null;
          this.lblFoto = "Foto perfil";
          this.lblCRM = "Foto documento conselho";
          this.picCrmName = null;
          this.getCities();
          this.getAreasExpertise();
        });
    });
  }
  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  salvar() {
    if (!this.form.valid) {
      this.openDialog("Informe todos os dados necessarios!");
      return;
    }

    if (!this.selectedCategory.id) {
      this.openDialog("Selecione a categoria!");
      return;
    }

    if (!this.estadoDocument.value) {
      this.openDialog("Selecione o estado do documento!");
      return;
    }
    this.loading = true;

    const data: any = {
      name: this.form.controls.nome.value,
      email: this.form.controls.email.value,
      profile: {
        genre: this.doctor.profile.genre,
        marital_status: this.doctor.marital_status,
        cel_phone: "55" + this.form.controls.telefone.value,
        rg: this.form.controls.rg.value,
        cpf: this.form.controls.cpf.value,
        address: this.form.controls.rua.value,
        address_number: this.form.controls.numero.value,
        address_complement: this.form.controls.complemento.value,
        neighbourhood: this.doctor.profile.neighbourhood,
        zip_code: this.form.controls.cep.value,
        photo: this.picProfileName
          ? "https://mediclog.blob.core.windows.net/images/" +
            this.picProfileName
          : this.doctor.profile.photo,
        occupation_area: this.selectedCategory.id,
        regional_council_document_number: this.form.controls.crm.value,
        regional_council_document_picture: this.picCrmName
          ? "https://mediclog.blob.core.windows.net/images/" + this.picCrmName
          : this.doctor.profile.regional_council_document_picture,
        regional_council_state: this.doctor.profile.regional_council_state,
      },
    };

    if (this.selectedAreaExpertise) {
      data.profile.area_of_expertise = this.selectedAreaExpertise.id;
    }

    if (this.cidade) data.profile.city = this.cidade.nome;
    if (this.estado) data.profile.state = this.estado.value;
    if (this.estadoDocument)
      data.profile.regional_council_state = this.estadoDocument.value;
    if (this.form.controls.dh_nascimento.value) {
      data.profile.date_of_birth =
        this.form.controls.dh_nascimento.value[4] +
        this.form.controls.dh_nascimento.value[5] +
        this.form.controls.dh_nascimento.value[6] +
        this.form.controls.dh_nascimento.value[7] +
        "-" +
        this.form.controls.dh_nascimento.value[2] +
        this.form.controls.dh_nascimento.value[3] +
        "-" +
        this.form.controls.dh_nascimento.value[0] +
        this.form.controls.dh_nascimento.value[1];
    }

    this.userInfoService
      .changeProfessionalInfo(this.doctor.health_professional_id, data)
      .subscribe(
        (data) => {
          this.loading = false;
          this.openDialogSucessoSave("Alterações salvas com sucesso!");
          // this.getProfessional();
        },
        (error) => {
          this.loading = false;
        }
      );

    this.loading = false;
  }
  openDialogSucessoSave(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  getCities() {
    if (!this.estado) {
      this.cidadeCombo.results.unshift({
        nome: "Cidade",
      });

      return;
    }
    this.cidadeCombo.results = [];
    this.loading = true;
    this.citiesService.getCities(this.estado.value).subscribe((data) => {
      this.cidadeCombo = data;
      this.cidadeCombo.results = data;

      // data.map((city) => {
      //  this.cidadeCombo.results.push({
      //   label: city.nome, value: city.nome,
      //})
      //  });

      this.cidadeCombo.results.unshift({
        nome: "Cidade",
      });

      if (this.doctor.profile.city) {
        const city = this.cidadeCombo.results.find(
          (city) =>
            city.nome
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase() ===
            this.doctor.profile.city
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
        );
        this.form.controls.cidade.setValue(this.doctor.profile.city);
        if (city) {
          this.cidade = city;
        } else {
          this.cidade = { label: "Cidade", value: null };
        }
      }
      this.loading = false;
    });
    this.loading = false;
  }

  onFileInputFoto(event) {
    if (event.target.name == "fotoPerfil") {
      this.picProfile = event.srcElement.files;
      this.lblFoto = event.srcElement.files[0].name;
      this.picProfileName = this.uuid4() + ".jpg";

      var customBlockSize =
        this.picProfile.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
      this.blobService.singleBlobPutThresholdInBytes = customBlockSize;

      this.blobService.createBlockBlobFromBrowserFile(
        "images",
        this.picProfileName,
        event.srcElement.files[0],
        { blockSize: customBlockSize },
        function (error, result, response) {
          if (error) {
            this.openDialogError(
              "Não foi possível fazer o upload, tente novamente"
            );
            this.picProfile = null;
            this.picProfileName = null;
            this.lblFoto = "Foto perfil";
          } else {
          }
        }.bind(this)
      );
    } else {
      this.picCrm = event.srcElement.files;
      this.lblCRM = event.srcElement.files[0].name;
      this.picCrmName = this.uuid4() + ".jpg";

      var customBlockSize =
        this.picCrm.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
      this.blobService.singleBlobPutThresholdInBytes = customBlockSize;

      this.blobService.createBlockBlobFromBrowserFile(
        "images",
        this.picCrmName,
        event.srcElement.files[0],
        { blockSize: customBlockSize },
        function (error, result, response) {
          if (error) {
            this.openDialogError(
              "Não foi possível fazer o upload, tente novamente"
            );
            this.picCrm = null;
            this.lblCRM = "Foto documento conselho";
            this.picCrmName = null;
          } else {
          }
        }.bind(this)
      );
    }
  }

  uuid4() {
    function hex(s, b) {
      return (
        s +
        (b >>> 4).toString(16) + // high nibble
        (b & 0b1111).toString(16)
      ); // low nibble
    }

    let r = crypto.getRandomValues(new Uint8Array(16));

    r[6] = (r[6] >>> 4) | 0b01000000; // Set type 4: 0100
    r[8] = (r[8] >>> 3) | 0b10000000; // Set variant: 100

    return (
      r.slice(0, 4).reduce(hex, "") +
      r.slice(4, 6).reduce(hex, "-") +
      r.slice(6, 8).reduce(hex, "-") +
      r.slice(8, 10).reduce(hex, "-") +
      r.slice(10, 16).reduce(hex, "-")
    );
  }
}
