import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { IHealthUnit } from "src/app/_models/health-unit.model";
import { DialogComponent } from "../dialog/dialog.component";
import { GridService } from "src/app/_services/grid.service";
import { HttpClient } from "@angular/common/http";

export interface DialogData {
  description: string;
  enableCancel: boolean;
  selectUnity: string;
  selectGrid: string;
  month: any;
}

@Component({
  selector: "medlog-create-schedule",
  templateUrl: "./create-schedule.component.html",
  styleUrls: ["./create-schedule.component.scss"],
})
export class CreateScheduleComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private healthUnitService: HealthUnitService,
    private healthProfessionalService: HealthProfessionalsService,
    private gridService: GridService,
    public dialog: MatDialog,
    private http: HttpClient
  ) {}
  healthUnits = [];
  selectedUnit;
  healthProfessionals;
  selectedProfessional;
  loading = true;
  pt: any;
  dateTime;
  modelDataInicio = 0;
  modelDataFim = 0;

  editModel: any;

  //escala
  gradeData;
  gradeAllData;
  isEdit = false;
  valorFaturar;
  valorHora;
  selectedGrade;

  onNoClick(): void {
    this.dialogRef.close("0");
  }

  clearTable() {
    this.gradeAllData = "";
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  async ngOnInit() {
    this.pt = {
      firstDayOfWeek: 0,
      dayNames: [
        "Domingo",
        "Segunda Feira",
        "Terça Feira",
        "Quarta Feira",
        "Quinta Feira",
        "Sexta Feira",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",
      dateFormat: "mm/dd/yy",
    };

    if (this.data.month) {
      this.modelDataInicio = this.data.month;
    }
    await this.getUnits();
    /*if(this.data.selectUnity){
      this.isEdit = true;
      this.dataWorkedSheet = this.data.selectUnity["planned_workshifts"];

      this.isSunday = this.data.selectUnity["days_of_week"]["sunday"];
      this.isMonday = this.data.selectUnity["days_of_week"]["monday"];
      this.isTuesday = this.data.selectUnity["days_of_week"]["tuesday"];
      this.isWednesday = this.data.selectUnity["days_of_week"]["wednesday"];
      this.isThursday = this.data.selectUnity["days_of_week"]["thursday"];
      this.isFriday = this.data.selectUnity["days_of_week"]["friday"];
      this.isSaturday = this.data.selectUnity["days_of_week"]["saturday"];
      this.nomeGrade = this.data.selectUnity["name"];


    }else{
      this.dataWorkedSheet.push({name:"", duration: "", start_time: "", horaFim: "", professionals_count: ""})
    }*/
  }

  getUnits() {
    const params = {
      health_unit_status: "enable",
    };
    this.healthUnitService.getHealthUnits(params).subscribe(async (data) => {
      this.loading = false;
      this.healthUnits = data["results"];

      this.healthUnits.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.healthUnits.unshift({ name: "Selecione a Unidade", id: 0 });
      if (this.data.selectUnity) {
        let selectedRole = this.healthUnits.find(
          (x) => x.id == this.data.selectUnity
        );
        this.selectedUnit = selectedRole;
        await this.getGrid();
      }
    });
  }

  getGrid() {
    this.gradeAllData = "";
    if (this.selectedUnit.id == 0) {
      let dat = [];
      this.gradeData = dat;
      this.gradeData.push({ name: "Selecione a Grade", id: 0 });
    } else {
      this.loading = true;
      this.gridService.getGrids(this.selectedUnit.id).subscribe((data) => {
        this.loading = false;
        this.gradeData = data["results"];
        this.gradeData.sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0; //default return value (no sorting)
        });

        this.gradeData.unshift({ name: "Selecione a Grade", id: 0 });
        if (this.data.selectGrid) {
          let selectedRole = this.gradeData.find(
            (x) => x.id == this.data.selectGrid
          );
          this.selectedGrade = selectedRole;
        }
      });
    }
  }

  criarEscala() {
    var date = new Date(this.modelDataInicio);
    var dtDb = date.toISOString().split("T");

    let data = {
      grid: this.selectedGrade.id,
      month: dtDb[0],
      dateMonth: date,
      invoice_value: this.valorFaturar,
      hourly_value: this.valorHora,
      unit: this.selectedUnit,
      grid_data: this.selectedGrade,
      app_notification: this.notifAppAtivada,
      whatsapp_notification: this.notifWhatsappAtivada,
    };

    this.dialogRef.close(data);
  }

  ngOnDestroy() {
    this.editModel = this.data.description;
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((_result) => {});
  }

  openDialogSucesso(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((_result) => {});
  }

  notifWhatsappAtivada: boolean = true;
  notifAppAtivada: boolean = true;
  toggleNotification(text: string, value: Boolean) {
    if (value) {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: "570px",
        height: "170px",
        panelClass: "verdePanel",
        data: { description: text + " ativada com sucesso." },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: "570px",
        height: "170px",
        panelClass: "vermelhoPanel",
        data: { description: text + " desativada com sucesso." },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
  }
}
