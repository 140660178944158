import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { MonitoringComponent } from "./home/monitoring/monitoring.component";
import { ClinicReportComponent } from "./home/clinic-report/clinic-report.component";
import { LoginComponent } from "./login/login.component";
import { HoursReportComponent } from "./home/hours-report/hours-report.component";
import { AuthGuard } from "./_guards/auth.guard";
import { DashBoardComponent } from "./home/dashboard/dashboard.component";
import { UnitsComponent } from "./home/units/units.component";
import { AddUnitsComponent } from "./home/addunits/addunits.component";
import { ProfessionalComponent } from "./home/professional/professional.component";
import { AddProfessionalComponent } from "./home/addprofessional/addprofessional.component";
import { ProfileComponent } from "./home/profile/profile.component";
import { EditUnitComponent } from "./home/editunit/editunit.component";
import { CheckinComponent } from "./home/checkin/checkin.component";
import { ClinicReportUserComponent } from "./home/clinic-report-user/clinic-report-user.component";
import { CadProfessionalComponent } from "./home/cadprofessional/cadprofessional.component";
import { GridComponent } from "./home/grid/grid.component";
import { ScheduleComponent } from "./home/schedule/schedule.component";
import { ScheduleTableComponent } from "./home/schedule-table/schedule-table.component";
import { ScheduleReportComponent } from "./home/schedule-report/schedule-report.component";
import { CommonModule } from "@angular/common";
import { CreateGridComponent } from "./home/create-grid/create-grid.component";
import { NotificationScheduleComponent } from "./home/notification-schedule/notification-schedule.component";
import { NotificationUnitComponent } from "./home/notification-unit/notification-unit.component";
import { PointRecordComponent } from "./home/point-record/point-record.component";
import { ScheduleMonitoringComponentV2 } from "./home/schedule-monitoring-v2/schedule-monitoring-v2.component";
const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      {
        path: "dashboard",
        component: DashBoardComponent,
        data: { title: "Dashboard" },
      },
      {
        path: "monitoramento",
        component: MonitoringComponent,
        data: { title: "Monitoramento" },
      },
      {
        path: "relatorio-unidades",
        component: ClinicReportComponent,
        data: { title: "Relatório Unidades" },
      },
      {
        path: "relatorio-horas",
        component: HoursReportComponent,
        data: { title: "Relatório Horas" },
      },
      {
        path: "relatorio-escala",
        component: ScheduleReportComponent,
        data: { title: "Relatório Escala" },
      },
      {
        path: "units",
        component: UnitsComponent,
        data: { title: "Unidades" },
      },
      {
        path: "addunidades",
        component: AddUnitsComponent,
        data: { title: "Unidades | Adicionar Unidades" },
      },
      {
        path: "professional",
        component: ProfessionalComponent,
        data: { title: "Profissionais de Saúde" },
      },
      {
        path: "addprofessional",
        component: AddProfessionalComponent,
        data: { title: "Profissionais | Convidar Profissionais" },
      },
      {
        path: "profile/:id",
        component: ProfileComponent,
        data: { title: "Profissional" },
      },
      {
        path: "editunit/:id",
        component: EditUnitComponent,
        data: { title: "Editar Unidade" },
      },
      {
        path: "checkin",
        component: CheckinComponent,
        data: { title: "Checkin" },
      },
      {
        path: "relatorio-profissional",
        component: ClinicReportUserComponent,
        data: { title: "Relatório" },
      },
      {
        path: "cad-profissional",
        component: CadProfessionalComponent,
        data: { title: "Cadastro Profissional" },
      },
      {
        path: "grid",
        component: GridComponent,
        data: { title: "Grade" },
      },
      {
        path: "create-grid",
        component: CreateGridComponent,
        data: { title: "Criar | Editar grade" },
      },
      {
        path: "schedule",
        component: ScheduleComponent,
        data: { title: "Escala" },
      },
      {
        path: "escala/:id/:schedule",
        component: ScheduleTableComponent,
        data: { title: "Escala" },
      },
      {
        path: "schedule-monitoring",
        component: ScheduleMonitoringComponentV2,
        data: { title: "Acompanhamento de Escala" },
      },
      {
        path: "noticationUnit",
        component: NotificationUnitComponent,
        data: { title: "Notificação por Unidade" },
      },
      {
        path: "notificationSchedule",
        component: NotificationScheduleComponent,
        data: { title: "Notificação por escala" },
      },
      {
        path: "point-record",
        component: PointRecordComponent,
        data: { title: "Gerenciar Jornadas Ativas" },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
